import { Component, OnInit, Input } from '@angular/core';

/**
 * Component to display multiConvis analysis for an operation.
 */
@Component({
  selector: 'app-multiconvis',
  templateUrl: './multiconvis.component.html',
  styleUrls: ['./multiconvis.component.scss']
})
export class MulticonvisComponent {
  /** Operation whose analysis to display is provided by the parent component */
  @Input()
  operationID;

  constructor() {}
}
