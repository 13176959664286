import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PreLoginService } from '../../shared/services/pre-login.service';
import { FormGroup } from '@angular/forms';

/**
 * Component to display password confirmation form.
 */
@Component({
  selector: 'app-password-confirm',
  templateUrl: './password-confirm.component.html',
  styleUrls: ['./password-confirm.component.scss']
})
export class PasswordConfirmComponent implements OnInit {
  /** Array where each element defines a field's icon and inputs */
  // For example:
  // fields = [
  //   password: {
  //     icon: 'fa-lock',
  //     inputs: [ {id: 'password', label: 'Password', type: 'password', validators: [Validators.required] } ],
  //     isPassword: true
  //   }
  // ]
  fields;

  /** Notify parent component after submitting form */
  @Output()
  formSubmitted = new EventEmitter<FormGroup>();

  constructor(private preLoginService: PreLoginService) {}

  /**
   * Called after initializing variables.
   */
  ngOnInit() {
    this.fields = [this.preLoginService.getFormFields().password];
  }

  /**
   * Handles form submission.
   *
   * @param form References submitted form
   */
  onSubmit(form: FormGroup) {
    this.formSubmitted.emit(form);
  }
}
