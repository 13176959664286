import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PiechartComponent } from './piechart/piechart.component';
import { PiechartEmotionsComponent } from './piechart-emotions/piechart-emotions.component';
import { PiechartPosNegComponent } from './piechart-pos-neg/piechart-pos-neg.component';
import { SentimentsComponent } from './sentiments.component';

/**
 * Module to display sentiment analysis for an operation.
 */
@NgModule({
  imports: [CommonModule],
  declarations: [
    PiechartComponent,
    PiechartEmotionsComponent,
    PiechartPosNegComponent,
    SentimentsComponent
  ],
  exports: [SentimentsComponent]
})
export class SentimentsModule {}
