import { Component, ComponentRef } from '@angular/core';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';
import { Operation } from '../../shared/models/operation.model';
import { OperationsService } from '../../shared/services/operations.service';
import { Router } from '@angular/router';

/**
 * Modal to display an operation's information, and if in progress, obtain the current status or cancel the operation.
 */
@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss']
})
export class OperationComponent implements IModalDialog {
  /** Operation to display */
  operation: Operation;
  /** Map of Operation keys to titles */
  operationTitles;
  /** Conversation keys */
  operationKeys;

  /** Fields starting from this index will be displayed */
  tableStartIndex = 1; // Fields where i < tableStartIndex represent ID and name, which are displayed in the modal title.

  /** Operation status */
  status: string;
  /** True if operation is in progress */
  inProgress: boolean;

  constructor(
    private operationsService: OperationsService,
    private router: Router
  ) {}

  /**
   * Called after initialization.
   *
   * Receives data from parent component and initializes variables.
   *
   * @param reference
   * @param options Contains modal options (including data) from parent component.
   */
  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<string>>
  ) {
    this.operation = options.data['operation'];
    this.operationTitles = options.data['operationTitles'];
    this.operationKeys = options.data['operationKeys'];

    this.status = this.operation.getStatus();
    this.inProgress = this.operationsService.inProgress(this.status);
  }

  /**
   * Updates status and inProgress.
   *
   * @param status Updated status
   */
  updateStatus(status: string) {
    this.status = status;
    this.inProgress = this.operationsService.inProgress(status);
  }

  /**
   * Handles 'Refresh Status' button click.
   */
  onRefreshStatus() {
    this.operationsService
      .updateStatus(this.operation.getID())
      .subscribe((status: string) => {
        if (status !== this.status) {
          this.updateStatus(status);

          // Notify DatatableComponent to re-fetch conversations and update view
          this.operationsService.dataChanged.next();
        }
      });
  }

  /**
   * Handles 'Cancel' button click.
   */
  onCancel() {
    this.operationsService
      .cancelOperation(this.operation.getID())
      .subscribe((status: string) => {
        this.updateStatus(status);

        // Check every 500ms if cancellation is still in progress.
        // Once cancelled, clear the interval.
        const interval = setInterval(() => {
          if (this.status === 'cancelling') {
            this.onRefreshStatus();
          } else {
            clearInterval(interval);
          }
        }, 500);
      });
  }

  /**
   * Handles 'View Analysis' button click, and navigates to the operation's page.
   */
  onViewAnalysis() {
    this.router.navigate(['/operations', this.operation.getID()]);
  }
}
