import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

/**
 * Service to display notification on success or error.
 *
 * ngx-toastr reference: https://www.npmjs.com/package/ngx-toastr
 */
@Injectable()
export class NotificationService {
  /** ngx-toastr options */
  // Set onActivateTick to fire changeDetectorRef.detectChanges().
  // Otherwise, toast is delayed.
  notificationConfig = {
    onActivateTick: true
  };

  constructor(private toastrService: ToastrService) {}

  /**
   * Displays error notification toast.
   *
   * @param message Notification message
   * @param title Optional notification title
   */
  error(message: string, title?: string) {
    this.toastrService.error(message, title, this.notificationConfig);
  }

  /**
   * Displays success notification toast.
   *
   * @param message Notification message
   * @param title Optional notification title
   */
  success(message: string, title?: string) {
    this.toastrService.success(message, title, this.notificationConfig);
  }
}
