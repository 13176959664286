import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PreLoginService } from '../../shared/services/pre-login.service';

/**
 * Component that displays a form from fields given by the parent component.
 */
@Component({
  selector: 'app-pre-login-form',
  templateUrl: './pre-login-form.component.html',
  styleUrls: ['./pre-login-form.component.scss']
})
export class PreLoginFormComponent implements OnInit {
  /** Array where each element defines a field's icon and inputs */
  // For example:
  // fields = [
  //   password: {
  //     icon: 'fa-lock',
  //     inputs: [ {id: 'password', label: 'Password', type: 'password', validators: [Validators.required] } ],
  //     isPassword: true
  //   }
  // ]
  @Input()
  fields;

  /** Text to display on submit button */
  @Input()
  submitText;

  /** Notify parent component after submitting form */
  @Output()
  formSubmitted = new EventEmitter<FormGroup>();

  /** References the form displayed */
  form: FormGroup;

  constructor(
    private preLoginService: PreLoginService,
    private router: Router
  ) {}

  /**
   * Called after initializing properties.
   */
  ngOnInit() {
    if (!this.fields) {
      // Get current URL without query parameters, and
      // use as key to get the form fields for this route.
      const url = this.router.url.split('?')[0];
      this.fields = this.preLoginService.getRoutes()[url].fields;
    }
    this.initForm();
  }

  /**
   * Initializes the form to display.
   */
  initForm() {
    // Create form controls for each form input
    const formControls = {};
    this.fields.forEach(field => {
      field.inputs.forEach(input => {
        formControls[input.id] = new FormControl(null, input.validators);
      });
    });

    this.form = new FormGroup(formControls);
  }

  /**
   * Handles toggle password icon (eye icon) click.
   *
   * @param field Element in this.fields whose form-group (in template) contains the toggled icon
   */
  togglePassword(field) {
    // Password field is
    // {
    // icon: 'fa-lock',
    //   inputs: [ {id: 'password', label: 'Password', type: 'password', validators: [Validators.required] } ],
    //   isPassword: true
    // }

    if (field.inputs[0].type === 'password') {
      field.inputs[0].type = 'text';
    } else {
      field.inputs[0].type = 'password';
    }
  }

  /**
   * Handles form submission.
   */
  onSubmit() {
    this.formSubmitted.emit(this.form);
  }
}
