import { Component, OnInit, Input } from '@angular/core';
import { CloudData, CloudOptions, ZoomOnHoverOptions } from 'angular-tag-cloud-module';
import { Dataset } from '../shared/models/dataset.model';
import { DatasetsService } from '../shared/services/datasets.service';
import { ActivatedRoute } from '@angular/router';
import { StatchartService } from '../shared/services/statchart.service';

@Component({
  selector: 'app-wordcloud',
  templateUrl: './wordcloud.component.html',
  styleUrls: ['./wordcloud.component.scss']
})
export class WordcloudComponent {
  @Input()
  public wcId: string;

  options: CloudOptions = {
    // if width is between 0 and 1 it will be set to the size of the upper element multiplied by the value 
    width: 1,
    height: 565,
    overflow: false,
    background: "background-color: white",
  };
  zoomOnHoverOptions: ZoomOnHoverOptions = {
    scale: 1.3, // Elements will become 130 % of current zize on hover
    transitionTime: 0.4, // it will take 1.2 seconds until the zoom level defined in scale property has been reached
    delay: 0 // Zoom will take affect after 0.8 seconds
  };

  public topicData: CloudData[];
  public needsData: CloudData[];
  public collection_formatted: CloudData[] = [];
  wcTopicToView: string;
  wcNeedsToView: string;
  wcTopicClicked: string = 'Physical';
  wcNeedClicked: string = 'Physical Needs';
  symptomsTopics: any = this.statchartService.symptomsTopics
  diagnosticsTopics: any = this.statchartService.diagnosticsTopics
  treatmentTopics: any = this.statchartService.treatmentTopics
  lifestyleTopics: any = this.statchartService.lifestyleTopics
  socialTopics: any = this.statchartService.socialTopics
  logisticTopics: any = this.statchartService.logisticTopics
  
  dataset: Dataset;
  datasetID: number;
  
  constructor(
    public statchartService: StatchartService,
    private datasetsService: DatasetsService,
    private route: ActivatedRoute,
  ) { 
    this.wcTopicToView = statchartService.wcTopicToView;
    this.wcNeedsToView = statchartService.wcNeedsToView;
  }

  ngOnInit() {
    this.datasetID = this.route.snapshot.params['id'];
    this.wcTopicClicked = '';
    this.wcNeedClicked = '';
    this.statchartService.topics$.subscribe((name: string) => {
      if(this.symptomsTopics.includes(name)){
        this.wcTopicClicked = 'Symptoms / ' + name;
      }
      else if(this.diagnosticsTopics.includes(name)){
        this.wcTopicClicked = 'Diagnostics / ' + name;
      }
      else if(this.treatmentTopics.includes(name)){
        this.wcTopicClicked = 'Treatment / ' + name;
      }
      else if(this.lifestyleTopics.includes(name)){
        this.wcTopicClicked = 'Lifestyle / ' + name;
      }
      else if(this.socialTopics.includes(name)){
        this.wcTopicClicked = 'Social / ' + name;
      }
      else if(this.logisticTopics.includes(name)){
        this.wcTopicClicked = 'Logistics / ' + name;
      }
      else {
        this.wcTopicClicked = name;
      }
      this.makeTopicWordCloud(name, this.wcId)
    });
    this.statchartService.needs$.subscribe((name: string) => {
      this.wcNeedClicked = name;
      this.makeNeedsWordCloud(name, this.wcId)
    });
  }

  makeTopicWordCloud(name, wcId) {
    this.datasetsService.getDataset(this.datasetID).subscribe((dataset: Dataset) => {
      let word_collection: any 

      if (wcId == 'topicWC') {
        this.collection_formatted = []
        switch (name) {
          case 'Physical':
            word_collection = dataset.getWCMedPhysical();
            break;
          case 'Emotional':
            word_collection = dataset.getWCMedEmotional();
            break;
          case 'Diagnosis':
              word_collection = dataset.getWCMedDiagnosis();
              break;
          case 'Laboratory':
              word_collection = dataset.getWCMedLaboratory();
              break;
          case 'Imaging':
            word_collection = dataset.getWCMedImaging();
            break;
          case 'Prescription':
              word_collection = dataset.getWCMedPrescription();
              break;
          case 'Alternative':
            word_collection = dataset.getWCMedAlternative();
            break;
          case 'Device':
              word_collection = dataset.getWCMedDevice();
              break;
          case 'Therapy':
              word_collection = dataset.getWCMedTherapy();
              break;
          case 'Counselling':
            word_collection = dataset.getWCMedCounselling();
            break;
          case 'Diet':
              word_collection = dataset.getWCMedDiet();
              break;
          case 'Exercise':
            word_collection = dataset.getWCMedExercise();
            break;
          case 'Substance':
              word_collection = dataset.getWCMedSubstance();
              break;
          case 'Housing':
              word_collection = dataset.getWCMedHousing();
              break;
          case 'Work':
            word_collection = dataset.getWCMedWork();
            break;
          case 'Soc. Service':
              word_collection = dataset.getWCMedSocialService();
              break;
          case 'Relationship':
            word_collection = dataset.getWCMedFF();
            break;
          case 'Cultural':
              word_collection = dataset.getWCMedCultural();
              break;
          case 'Outpatient':
              word_collection = dataset.getWCMedOutpatient();
              break;
          case 'Hospital':
            word_collection = dataset.getWCMedHospitalization();
            break;
          case 'Technical / IT':
              word_collection = dataset.getWCMedTechnical();
              break;
          default: 
            word_collection = dataset.getWordCloud();
            break;
        }

        for (var key in word_collection) {
          let formatted_entry = {text: key, weight: word_collection[key], color: "black"}
          this.collection_formatted.push(formatted_entry)
        }
  
        this.topicData = this.collection_formatted;
      }
    })
  }

  makeNeedsWordCloud(name, wcId) {
    this.datasetsService.getDataset(this.datasetID).subscribe((dataset: Dataset) => {
      let word_collection: any 

      if(wcId == 'needsWC') {
        this.collection_formatted = []
        switch (name) {
          case 'Emotional Needs':
            word_collection = dataset.getWCNTEmotional();
            break;
          case 'Social Needs':
            word_collection = dataset.getWCNTSocial();
            break;
          case 'Health Info Needs':
            word_collection = dataset.getWCNTHealthInfo();
            break;
          default: 
            word_collection = dataset.getWCNTPhysical();
            break;
        }
      
        for (var key in word_collection) {
          let formatted_entry = {text: key, weight: word_collection[key], color: "black"}
          this.collection_formatted.push(formatted_entry)
        }
      
        this.needsData = this.collection_formatted;
      }
    })
  }
}
