import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Service to control access to requested routes.
 */
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * Returns an Observable that returns true if navigation to a route is allowed.
   *
   * If logged in, disallow access to pre-login pages (login, signup, password reset)
   * and redirect to dashboard.
   *
   * Otherwise, disallow access to application and redirect to login page.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isAuthenticated().pipe(
      map((loggedIn: boolean) => {
        // Pre-login pages are under route /STAT (/STAT/login)
        if (state.url.includes('/STAT') && loggedIn) {
          this.router.navigate(['/']);
          return false;
        } else if (!state.url.includes('/STAT') && !loggedIn) {
          this.router.navigate(['/STAT'], {
            queryParams: { returnUrl: state.url }
          });
          return false;
        }

        return true;
      })
    );
  }

  /**
   * Returns an Observable that returns true if navigation to a child route is allowed.
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
