import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Dataset } from '../shared/models/dataset.model';
import { DatasetsService } from '../shared/services/datasets.service';
import { StatchartService } from '../shared/services/statchart.service';
import { ActivatedRoute } from '@angular/router';
import * as ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-statchart',
  templateUrl: './statchart.component.html',
  styleUrls: ['./statchart.component.scss']
})
export class StatchartComponent implements OnInit {
  @Input()
  public title: string;
  @Input()
  chartId: string;
  @Input()
  showFilter: boolean;

  dataset: Dataset;
  datasetID: number;
  genderAgeDataReady: boolean = false;
  topicsDataReady: boolean = false;
  needsDataReady: boolean = false;
  filterText: string;
  barClicked: string;

  categories: any = this.statchartService.parentTopicLabels

  constructor(
    private statchartService: StatchartService,
    private datasetsService: DatasetsService,
    private route: ActivatedRoute,
  ) { }

  public topicsChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
          ticks: {
            beginAtZero: true,
            fontColor:['#751A33','#751A33', '#751A33', 
            '#D4B95E','#D4B95E', '#D4B95E', '#D4B95E', 
            '#4EA2A2','#4EA2A2', '#4EA2A2', '#4EA2A2', '#4EA2A2','#4EA2A2',
            '#B34233', '#B34233','#B34233', '#B34233', 
            '#D28F33', '#D28F33', '#D28F33', '#D28F33', '#D28F33', '#D28F33', 
            '#578B73', '#578B73', '#578B73','#578B73',
            '#1A8693', '#1A8693', '#1A8693','#1A8693'],
            callback: function(tickValue, index, ticks) {
              return tickValue;
            }
          }
      }]
    },
    plugins: [ChartDataLabels],
    onClick: function(e, barClicked) {
      if (barClicked.length != 0){
        var element = barClicked[0]._view.label;
        this.statchartService.changeTopicWCToView(element);
      }
    }.bind(this)
  };

  public ageChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        
      }]
    }
  };

  public genderChartOptions = {
    tooltips: {
      enabled: true
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
              sum += data;
          });
          let percentage = (value*100 / sum).toFixed(2)+"%";
          return percentage;
        },
        color: 'black',
        font: {
          size: 15,
        }
      }
    }
  }

  public needsChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      xAxes: [{
          ticks: {
              beginAtZero: true
          }
      }]
    },
    plugins: [ChartDataLabels],
    onClick: function(e, barClicked) {    
      if (barClicked.length != 0){
        var element = barClicked[0]._view.label;
        
        barClicked[0]._chart.config.data.datasets[0].datalabels.color = "pink";
        this.statchartService.changeNeedsWCToView(element + " Needs");
      }
    }.bind(this)
  };

  public ageChartLabels = this.statchartService.ageLabels;
  public ageChartType = 'bar';
  public ageChartLegend = false;
  public ageChartData = [];
  public ageChartColors = [{
    backgroundColor: ['#616161','#616161', '#616161', '#616161', '#616161'],
    borderColor:"#70ABAF",
    hoverBorderColor:"#457B9D"
  }];

  public genderChartLabels = this.statchartService.genderLabels;
  public genderChartData = [];
  public genderChartType = 'doughnut';
  public genderChartColors = [{
    // backgroundColor: ['#F3565D','#FB9D9A', '#FDCCAD']
    backgroundColor: ['#4DB7AC','#9E81BB', '#FDCCAD']
  }];

  public topicsChartLabels = this.statchartService.topicLabels;
  public topicsChartType = 'horizontalBar';
  public topicsChartLegend = false;
  public topicsChartData = [];
  public topicsChartColors = [{
    backgroundColor: ['#32292F','#32292F', '#32292F', 
    '#70706F','#70706F', '#70706F', '#70706F', 
    '#7D7D7A','#7D7D7A', '#7D7D7A', '#7D7D7A', '#7D7D7A','#7D7D7A',
    '#8E8D8D', '#8E8D8D','#8E8D8D', '#8E8D8D', 
    '#B3B6B5', '#B3B6B5', '#B3B6B5', '#B3B6B5', '#B3B6B5', '#D28F33', 
    '#BEC0C2', '#BEC0C2', '#BEC0C2','#BEC0C2',
    '#1A8693', '#1A8693', '#1A8693','#1A8693']
  }];

  public needsChartLabels = this.statchartService.needLabels;
  public needsChartType = 'horizontalBar';
  public needsChartLegend = false;
  public needsChartData = [];
  public needsChartColors = [{
    // backgroundColor: ['#E07605','#8D6236', '#A63410', '#711C00']
    backgroundColor: ['#32292F', '#70706F', '#B3B6B5', '#BEC0C2'],
  }];

  ngOnInit() {
    this.datasetID = this.route.snapshot.params['id'];
    this.makeChart();
  }

  makeChart(){
    this.datasetsService.getDataset(this.datasetID).subscribe((dataset: Dataset) => {
      if (this.title == 'Gender/Age Distribution'){
        let ageGroup = Object.values(dataset.getAgeGroup());
        this.ageChartData = 
          [{ 
            data: ageGroup, 
            label: 'Age', 
            datalabels: {align: 'end', anchor: 'end', color: 'black', font: {size: 16} }
          }];
        let maxAge = Math.max(...ageGroup);
        this.ageChartOptions.scales = { yAxes: [{ ticks: { max: maxAge+5 } }] }
        // color: ['#99E1D9', '#A8DADC', '#70ABAF', '#457B9D', '#32292F'], 
        this.genderChartData = [
          dataset.getMaleCount(), 
          dataset.getFemaleCount(), 
          dataset.getXCount()
        ]; 
        this.genderAgeDataReady = true;
      }
      
      if (this.title == 'Conversational Topics'){
        this.topicsChartData = 
          [{
            label: 'Topics',
            data: [
              dataset.getMedTopics()['physical'], 
              dataset.getMedTopics()['emotional'], 
              0,
              dataset.getMedTopics()['diagnosis'], 
              dataset.getMedTopics()['laboratory'], 
              dataset.getMedTopics()['imaging'], 
              dataset.getMedTopics()['prescription'], 
              dataset.getMedTopics()['alternative'], 
              0,
              dataset.getMedTopics()['device'], 
              dataset.getMedTopics()['therapy'], 
              dataset.getMedTopics()['counselling'], 
              0,
              dataset.getMedTopics()['diet'], 
              dataset.getMedTopics()['exercise'], 
              dataset.getMedTopics()['substance'], 
              0,
              dataset.getMedTopics()['housing'], 
              dataset.getMedTopics()['work'], 
              dataset.getMedTopics()['social'], 
              dataset.getMedTopics()['ff'], 
              dataset.getMedTopics()['cultural'], 
              0,
              dataset.getMedTopics()['outpatient'], 
              dataset.getMedTopics()['hospitalization'],
              dataset.getMedTopics()['technical'] 
            ],
            datalabels: {align: 'start', anchor: 'end', color: 'white', font: {size: 14} }
          }];
        this.topicsDataReady = true;
      }

      if (this.title == 'Need Types') {
        var needFrequency = dataset.getNeedTypes()

        this.needsChartData = 
        [{ 
          data: [ needFrequency['physical_need'], needFrequency['emotional_need'], needFrequency['social_need'], needFrequency['health_info_need'] ], 
          label: 'Need Types',
          datalabels: {align: 'end', anchor: 'end', color: 'black', font: {size: 14} }
        }];
        this.needsDataReady = true;
      }
      // color: ['#ED553B', '#F6D55C', '#20639B', '#3CAEA3' ],
    });
  }

  onButtonGroupClick($event){
    let clickedElement = $event.target || $event.srcElement;
    this.filterText = clickedElement.innerText;
    
    if(clickedElement.nodeName === "BUTTON") {
      let isCertainButtonAlreadyActive = clickedElement.parentElement.querySelector(".active");
      // if a Button already has Class: .active
      this.makeChart();
      if(isCertainButtonAlreadyActive) {
        isCertainButtonAlreadyActive.classList.remove("active");
      }
      clickedElement.className += " active";
    }
  }
}