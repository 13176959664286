import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from './notification.service';

/**
 * Service to override Angular's ErrorHandler (global error handler) to
 * display notification upon error.
 *
 * Reference: https://medium.com/@aleixsuau/error-handling-angular-859d529fa53a
 */
@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  // ErrorHandler is created before providers, so dependency injection
  // in the constructor to get other services is not possible.
  //
  // To inject a service, must manually call this.injector.get(service)
  constructor(private injector: Injector) {}

  /**
   * Handles all errors.
   *
   * @param error Error to handle
   */
  handleError(error: Error | HttpErrorResponse) {
    const notificationService = this.injector.get(NotificationService);

    console.error(error);

    if (error instanceof HttpErrorResponse) {
      // Server or connection error.
      if (!navigator.onLine) {
        notificationService.error('No internet connection.', 'Error');
      } else {
        // CORS error
        if (error.status === 0) {
          notificationService.error(
            'No Access-Control-Allow-Origin header is present on the requested resource.',
            'Access Not Allowed'
          );
        }

        // HTTP error
        notificationService.error(error.message, 'Error ' + error.status);
      }
    } else {
      // Client error
      notificationService.error(error.message, 'Error');
    }
  }
}
