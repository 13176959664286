import { Injectable } from '@angular/core';
import { Conversation } from '../models/conversation.model';
import { HttpService } from './http.service';
import { Subject, Observable } from 'rxjs';
import { NotificationService } from './notification.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

/**
 * Service to interact with the server and perform operations to:
 * get conversations;
 * upload conversation files;
 * edit a conversation's tags;
 * remove a conversation from the system.
 */
@Injectable()
export class ConversationsService {
  /** Conversations fetched from the database */
  conversations: Conversation[] = [];

  /** Parent route of all conversation endpoints */
  conversationsUrl = '/conversation';
  /** Url to GET a list of conversations */
  listConversationsUrl = this.conversationsUrl + '/list_conversations';
  /** Url to GET the 5 most recent conversations */
  allConversationsUrl = this.conversationsUrl + '/all/';
  /** Url to POST a conversation file */
  uploadConversationUrl = this.conversationsUrl + '/upload';
  /** Url to POST tags to add */
  tagConversationUrl = this.conversationsUrl + '/tag';
  /** Url to POST tags to remove */
  untagConversationUrl = this.conversationsUrl + '/untag';
  /** Url to DELETE a conversation */
  removeConversationUrl = this.conversationsUrl + '/remove';

  /** ID of last conversation fetched (for infinite scrolling) */
  lastConversationID: number;
  /** Number of responses to fetch */
  numResponses = 15;

  /** Call after conversations in the database have been modified */
  dataChanged = new Subject();
  /** Call after scrolling to the bottom of a conversations list */
  dataScrolled = new Subject();
  /** Call after conversations have been filtered, and pass the filtered conversations. */
  dataFiltered = new Subject<Conversation[]>();
  /** Call after clicking a conversation in the datatable */
  conversationClicked = new Subject<Conversation>();

  /** Map of Conversation keys to titles */
  conversationTitles = {
    conversation_id: 'ID',
    conversation_name: 'Name',
    timestamp_start: 'Start Date',
    timestamp_end: 'End Date',
    timestamp_upload: 'Upload Date',
    num_msgs: 'Message #',
    num_participants: 'Participant #',
    tags: 'Tags'
  };

  constructor(
    private httpService: HttpService,
    private notificationService: NotificationService
  ) {}

  /**
   * Returns an Observable that returns a list of all Conversations.
   *
   * @param scrolling If false, returns most recent conversations.
   * Otherwise, returns conversations uploaded before lastConversationID and current conversations.
   * @param numResponses Number of responses to return
   */
  getData(
    scrolling: boolean,
    numResponses?: number
  ): Observable<Conversation[]> {
    const url = this.httpService.getUrl() + this.listConversationsUrl;

    const params = {
      num_res: numResponses ? numResponses : this.numResponses
    };

    if (scrolling) {
      params['last_id'] = this.lastConversationID;
    }

    return this.httpService.sendGet(url, params).pipe(
      map(response => {
        const key = 'conversation_list';

        if (!scrolling) {
          // Most recent conversations
          this.conversations = [];
          this.conversations = this.getConversationsList(response[key]);
        } else {
          // Current conversations + newly loaded conversations
          this.conversations.push(...this.getConversationsList(response[key]));
        }

        // Set this.lastConversationID
        if (this.conversations.length) {
          this.lastConversationID = this.conversations[
            this.conversations.length - 1
          ].getID();
        }

        return this.conversations.slice();
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Returns a Conversation.
   *
   * @param conversation Conversation from GET result
   */
  getConversationModel(conversation: any): Conversation {
    return new Conversation(
      conversation.conversation_id,
      conversation.conversation_name,
      this.getFormattedDate(conversation.msg_start_timestamp),
      this.getFormattedDate(conversation.msg_end_timestamp),
      this.getFormattedDate(conversation.upload_timestamp),
      conversation.num_msgs,
      conversation.num_participants,
      conversation.tags || []
    );
  }

  /**
   * Returns a formatted date string.
   *
   * @param date Date string from GET result
   */
  getFormattedDate(date: string): string {
    return new Date(date).toLocaleDateString();
  }

  /**
   * Returns a list of Conversations.
   *
   * @param conversations Conversation list from GET result
   */
  getConversationsList(conversations: any): Conversation[] {
    if (!conversations) {
      return [];
    }

    const list: Conversation[] = [];
    for (const conversation of conversations) {
      list.push(this.getConversationModel(conversation));
    }

    return list;
  }

  /**
   * Returns an Observable that uploads a conversation and displays notification upon success or error.
   *
   * @param formData Conversation file to upload
   */
  uploadConversation(formData: FormData): Observable<void> {
    const url = this.httpService.getUrl() + this.uploadConversationUrl;
    return this.httpService.sendPost(url, formData).pipe(
      map(response => {
        if (response['status'] === 'success') {
          this.notificationService.success(
            'Uploaded ' + formData.get('convo_file')['name'] + '!'
          );
        } else {
          throw Error(
            'Failed upload for ' +
              formData.get('convo_file')['name'] +
              ': ' +
              response['details']
          );
        }
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Returns an Observable that modifies tags for a conversation and displays notification upon success or error.
   *
   * @param conversationID Conversation to modify
   * @param tags Tags to add or remove
   * @param add If true, add tags. Otherwise, remove tags.
   */
  tagConversation(
    conversationID: number,
    tags: string[],
    add: boolean
  ): Observable<void> {
    if (!tags.length) {
      return of(null);
    }

    const url =
      this.httpService.getUrl() +
      (add ? this.tagConversationUrl : this.untagConversationUrl);

    const data = {
      convo_id: conversationID,
      tags: tags
    };

    return this.httpService.sendPost(url, data).pipe(
      map(response => {
        if (response['status'] !== 'error') {
          this.notificationService.success(
            'Conversation ' + (add ? 'tagged!' : 'untagged!')
          );
        } else {
          throw Error('Tag conversation failed: ' + response['details']);
        }
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Returns an Observable that deletes a conversation.
   *
   * @param conversationID Conversation to delete
   */
  removeConversation(conversationID: number): Observable<void> {
    const data = {
      conversationID: conversationID
    };
    const url = this.httpService.getUrl() + this.removeConversationUrl;

    return this.httpService.sendDelete(url, data).pipe(
      map(response => {
        if (response['status'] === 'success') {
          this.notificationService.success(
            'Conversation ' + conversationID + ' removed!'
          );
        } else {
          throw Error(response['details']);
        }
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Returns a map of Conversation keys to titles.
   */
  getTitles(): Object {
    return this.conversationTitles;
  }

  /**
   * Returns a list of Conversation keys.
   */
  getKeys(): string[] {
    return Object.keys(this.conversationTitles);
  }
}
