import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  ViewContainerRef
} from '@angular/core';
import { Conversation } from '../shared/models/conversation.model';
import { Dataset } from '../shared/models/dataset.model';
import { ConversationsService } from '../shared/services/conversations.service';
import { DatasetsService } from '../shared/services/datasets.service';
import { Subscription, Observable } from 'rxjs';
import { OperationsService } from '../shared/services/operations.service';
import { Operation } from '../shared/models/operation.model';
import * as $ from 'jquery';
import 'datatables.net';

import { ModalDialogService } from 'ngx-modal-dialog';
import { DatasetRemoveComponent } from '../datasets/dataset-remove/dataset-remove.component';
import { DatasetEditComponent } from '../datasets/dataset-edit/dataset-edit.component';
import { DatasetNewComponent } from '../datasets/dataset-new/dataset-new.component';
import { Router } from '@angular/router';

/**
 * DataTable for displaying conversations, datasets, or operations.
 *
 * Reference: https://datatables.net/
 */
@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, OnDestroy {
  /**
   * Type of data to display is provided by the parent component and
   * must be either 'Conversation', 'Dataset', or 'Operation'
   */
  @Input()
  type: string;

  /**
   * Service used is deteremined by the type, and is either
   * ConversationsService, DatasetsService, or OperationsService.
   */
  service: string;

  /**
   * ID of this dataset is provided by the parent component.
   *
   * If datasetID exists, display either conversations or operations belonging to this dataset.
   * Otherwise, display either all conversations, all datasets, or all operations.
   */
  @Input()
  datasetID: number;

  /** Notify parent component after clicking a row */
  @Output()
  rowClicked = new EventEmitter<Conversation | Dataset | Operation>();
  /** Notify parent component after selecting/unselecting a row */
  @Output()
  rowsSelectedChanged = new EventEmitter<
    Conversation[] | Dataset[] | Operation[]
  >();
  /** Notify parent component after fetching data from the database */
  @Output()
  dataChanged = new EventEmitter<Conversation[] | Operation[]>(); // Parent component will filter passed data

  /** References DataTable in template */
  dataTable: any;
  /** Map of data keys to titles */
  titles: {};
  /** Data keys */
  keys: string[];
  /** Data fetched from the database */
  rows: Conversation[] | Dataset[] | Operation[];
  /** Selected data */
  rowsSelected = [];
  /** If true, all rows will be selected */
  selectAll = false;

  dataset: Dataset;

  /** Subscription to service.dataChanged for updating view after data modifications */
  dataChangedSubscription = new Subscription();
  /** Subscription to service.dataFiltered for updating view after data filtering */
  dataFilteredSubscription = new Subscription();
  /** Subscription to service.dataScrolled for infinite scrolling */
  dataScrolledSubscription = new Subscription();

  constructor(
    private conversationsService: ConversationsService,
    private datasetsService: DatasetsService,
    private operationsService: OperationsService,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef,
    private router: Router
  ) {}

  /**
   * Called after initializing properties.
   */
  ngOnInit() {
    this.initData();
  }

  /**
   * Called when component is destroyed.
   */
  ngOnDestroy() {
    this.destroyDataTable();
    this.dataChangedSubscription.unsubscribe();
    this.dataFilteredSubscription.unsubscribe();
    this.dataScrolledSubscription.unsubscribe();
  }

  /**
   * Destroys DataTable.
   */
  destroyDataTable() {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  }

  /**
   * Initialize DataTable.
   *
   * Reference: https://datatables.net/reference/option/
   */
  initDataTable() {
    const table: any = $('#datatable');
    this.dataTable = table.DataTable({
      columnDefs: [{ orderable: false, targets: 0 }], // Checkbox column (column 0) should not be orderable
      order: [[4, 'desc']], // By default, sort by ID (column 1)
      searching: false, // Use custom FilterComponent
      paging: false, // Use infinite scrolling
      fixedHeader: { header: true },
      info: false
    });
  }

  /**
   * Refreshes DataTable.
   */
  refreshDataTable() {
    // Have to destroy and re-init DataTable
    this.destroyDataTable();
    setTimeout(() => this.initDataTable(), 0);
  }

  /**
   * Updates DataTable with updated data.
   *
   * @param data Updated data
   */
  updateDataTable(data: any) {
    if (this.type !== 'Dataset') {
      // If displaying conversations/operations, notify respective components to apply filter on new data
      this.dataChanged.emit(data);
    } else {
      // If displaying operations, update data and refresh table
      this.rows = data;
      this.refreshDataTable();
    }
  }

  /**
   * Returns an Observable that returns the list of conversations belonging to this dataset.
   *
   * @param scrolling Set to true if loading more conversations for infinite scrolling.
   */
  getConversationsDataset(scrolling: boolean): Observable<Conversation[]> {
    return this.datasetsService.listConversations(this.datasetID, scrolling);
  }

  /**
   * Returns an Observable that returns the list of operations belonging to this dataset.
   *
   * @param scrolling Set to true if loading more operations for infinite scrolling.
   */
  getOperationsDataset(scrolling: boolean): Observable<Operation[]> {
    return this.datasetsService.listOperations(this.datasetID, scrolling);
  }

  /**
   * Gets data and updates DataTable.
   *
   * If this.datasetID exists and this.type === 'Operation', gets operations in this dataset.
   * If this.datasetID exists and this.type === 'Conversation', gets conversations this dataset.
   * Otherwise, gets either all conversations, all datasets, or all operations.
   *
   * @param scrolling Set to true if loading more data for infinite scrolling.
   */
  getData(scrolling?: boolean) {
    let getDataFn = this[this.service].getData(scrolling);

    if (this.datasetID) {
      getDataFn =
        this.type === 'Operation'
          ? this.getOperationsDataset(scrolling)
          : this.getConversationsDataset(scrolling);
    }
    getDataFn.subscribe((data: any) => this.updateDataTable(data));
  }

  /**
   * Initializes data.
   */
  initData() {
    this.service = this.type.toLowerCase() + 'sService';
    this.titles = this[this.service].getTitles();
    this.keys = this[this.service].getKeys();

    this.getData();
    this.dataChangedSubscription = this[this.service].dataChanged.subscribe(
      () => this.getData()
    );

    // dataChanged is called by this.onScroll()
    // Need to GET more data from DB for infinite scrolling
    this.dataScrolledSubscription = this[this.service].dataScrolled.subscribe(
      () => this.getData(true)
    );

    // dataFiltered is called by Conversations/Operations component after filtering data
    // Need to update data to filtered data, and refresh DataTable
    if (this.type !== 'Dataset') {
      this.dataFilteredSubscription = this[this.service].dataFiltered.subscribe(
        data => {
          this.rows = data;
          this.refreshDataTable();
        }
      );
    }
  }

  /**
   * Handles scrolling to bottom of multiple select input for datasets.
   */
  onScroll() {
    this[this.service].dataScrolled.next();
  }

  /**
   * Handles row click, and passes clicked data to parent component.
   *
   * @param data Clicked data
   */
  onRowClick(data) {
    this.rowClicked.emit(data);
  }

  /**
   * Handles row select and unselect, and passes selected data to parent component.
   */
  onCheck(event, data) {
    event.stopPropagation(); // Otherwise event will trigger modals for row clicks

    if (event.target.checked) {
      // Row is selected
      this.rowsSelected.push(data);
    } else {
      // Row is unselected (remove data from this.rowsSelected)
      const index = this.rowsSelected.indexOf(data, 0);
      if (this.rowsSelected.indexOf(data, 0) > -1) {
        this.rowsSelected.splice(index, 1);
      }
    }
    
    this.rowsSelectedChanged.emit(this.rowsSelected);
  }

  /**
   * Handles 'Select All' radio button select and unselect.
   *
   * @param selectAll True if 'Select All' radio button is selected.
   */
  onSelectAll(selectAll: boolean) {
    this.selectAll = selectAll;
    this.rowsSelected = selectAll ? this.rows.slice() : [];
    this.rowsSelectedChanged.emit(this.rowsSelected);
  }

  /**
   * Handles 'Remove Datasets' button click, and trigger modal to
   * remove datasets from the system.
   */
  onRemoveDatasetsClick() {
    this.modalService.openDialog(this.viewRef, {
      title: 'Remove Datasets',
      childComponent: DatasetRemoveComponent,
      data: {
        datasets: this.rowsSelected
      }
    });
  }

  onEditDatasetClick() {
    this.modalService.openDialog(this.viewRef, {
      title: 'Edit Dataset',
      childComponent: DatasetEditComponent,
      data: { dataset: this.rowsSelected[0] }
    });
  }

  onDatasetClick() {
    this.router.navigate(['/datasets-overview', this.rowsSelected[0].dataset_id]);
  }

  /**
   * Handles 'New Dataset' button cick, and triggers modal to
   * select conversations and add them to a new dataset.
   */
  onNewDatasetClick() {
    this.modalService.openDialog(this.viewRef, {
      title: 'New Dataset',
      childComponent: DatasetNewComponent
    });
  }
}
