import { Component, ComponentRef, ViewContainerRef, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';
import { Dataset } from '../../shared/models/dataset.model';
import { DatasetsService } from '../../shared/services/datasets.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'app-dataset-filter',
    templateUrl: './dataset-filter.component.html',
    styleUrls: ['./dataset-filter.component.scss']
  })
  export class DatasetFilterComponent implements IModalDialog, OnDestroy, AfterViewInit {
    @ViewChild('form')
    form: NgForm;
    
    /** Dataset to edit */
    dataset: Dataset;
  
    datasetName: string = null;
    // Filter properties
    clinics: any[] = [];
    selectedClinicNames: any[] = [];
    clinicSelections: {[key: string]: boolean} = {};
    clinicsLoaded: boolean = false;

    convo_earliest_date: string = null;
    convo_latest_date: string = null;
    
    // Renamed to match HTML
    dateRange = {
      startDate: null,
      endDate: null
    };

    private dialogRef: any;
    
    // For tracking subscriptions
    private subscriptions: Subscription[] = [];
    
    constructor(
      private datasetsService: DatasetsService,
      private datePipe: DatePipe,
      private toastrService: ToastrService
    ) {}
  
    dialogInit(
      reference: ComponentRef<IModalDialog>,
      options: Partial<IModalDialogOptions<any>>
    ) {
        this.clinicsLoaded = false;
        this.dialogRef = reference
        this.dataset = options.data['dataset']
        console.log('Dataset to filter:', this.dataset)
        const datasetId = this.dataset ? this.dataset.getID() : null
        let user_id = localStorage.getItem('user_id')

        // Load clinics for filtering
        this.loadClinics(user_id, String(datasetId))
        this.datasetsService.getDataset(datasetId).subscribe(
          dataset => {
            this.convo_earliest_date = dataset['convo_earliest_timestamp'];
            this.convo_latest_date = dataset['convo_latest_timestamp'];

            this.dateRange = {
                startDate: this.formatDateString(this.convo_earliest_date),
                endDate: this.formatDateString(this.convo_latest_date),
            }

            this.datasetName = null;
          },
          error => console.error('Error loading dataset', error)
        );
    }

    updateSelectedClinics() {
      console.log('Clinics:', this.clinics)
      console.log('Clinic selections:', this.selectedClinicNames)
      this.selectedClinicNames = this.clinics
        .filter(clinic => clinic.selected)
        .map(clinic => clinic.value || clinic);
    }
    
    loadClinics(userId: string, datasetId:string) {
      const sub = this.datasetsService.getClinics(userId, datasetId).subscribe(
        clinics => {
          this.clinics = clinics.map(clinic => {
            return {
              clinicName: clinic,
              selected: false
              };
          })
          console.log('Clinics loaded:', clinics);
        },
        error => console.error('Error loading clinics', error)
      );
      this.subscriptions.push(sub);
      this.clinicsLoaded = true;
    }
    
    private formatDateString(dateStr: string): string {
      if (!dateStr) return null;
      const date = new Date(dateStr);
      return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
  
    // Matching the form submission handler
    onConfirm() {
      if (this.form.valid) {
        this.applyFilters();
      } else {
        console.error('Form is invalid');
        Object.keys(this.form.controls).forEach(key => {
          this.form.controls[key].markAsTouched();
        });
      }
    }
    
    // Matching the button click handler
    applyFilters() {
      const filterParams = {
        clinicNames: this.selectedClinicNames.map(clinic => clinic.clinicName),
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        datasetId: this.dataset ? String(this.dataset.getID()) : null,
        datasetName: this.datasetName
      };
      
      if (this.formatDateString(this.dateRange.startDate) > this.formatDateString(this.dateRange.endDate)) {
        this.toastrService.error(
          'Start date cannot be later than end date',
          'Invalid date range',
          { timeOut: 5000 }
        );
        return;
      }
      const sub = this.datasetsService.filterDatasets(filterParams.clinicNames, filterParams.startDate, filterParams.endDate, filterParams.datasetId, filterParams.datasetName)
        .subscribe(
          dataset_id => {
            if (dataset_id != undefined) {
              setTimeout(() => {
                this.toastrService.info(
                  `Filtered dataset: ${dataset_id} created successfully.`,
                  'Filtering complete',
                  { timeOut: 5000 }
                );
              });
            }
          },
          error => console.error('Error applying filters', error)
        );
      this.subscriptions.push(sub);
    }
    
    // Matching the cancel button
    cancel() {
      try {
        if (this.dialogRef && typeof this.dialogRef.close === 'function') {
          this.dialogRef.close(null);
        } 
        else if (this.dialogRef && this.dialogRef.instance && 
                  typeof this.dialogRef.instance.close === 'function') {
          this.dialogRef.instance.close(null);
        } else {
          console.error('Unable to find close method on dialogRef');
        }
      } catch (error) {
        console.error('Error closing dialog:', error);
      }
    }
    
    ngOnDestroy() {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ngAfterViewInit() {
      // Small delay to ensure the datatable is fully rendered
      setTimeout(() => {
          this.toastrService.info(
            'Pro tip: You could click on anywhere else the popup to close it.',
            'Close this popup',
            { timeOut: 5000 }
          );
      }, 500);
    }
  }
  