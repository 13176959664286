import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

/**
 * Component to display conversation filters or operation filters.
 */
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  /** Type of data to display is provided by the parent component and must be either 'Conversation' or 'Operation' */
  @Input()
  type;

  /** Notifies parent component after submitting search input */
  @Output()
  searchInput = new EventEmitter<string>();
  /** Notifies parent component after submitting filter */
  @Output()
  filterInput = new EventEmitter<{}>();

  /** Search string input */
  searchString: string;

  constructor() {}

  /**
   * Handles search string input.
   *
   * @param event Key event
   */
  onSearchInput(event) {
    // Notifies parent component to apply search string on submit button click, enter key,
    // or empty search string
    if (
      event.type === 'click' ||
      (event.type === 'keyup' &&
        (event.code === 'Enter' || event.target.value === ''))
    ) {
      this.searchInput.emit(this.searchString);
    }
  }

  /**
   * Handles filter submission.
   *
   * @param event Filter object
   */
  onFilterInput(event) {
    this.filterInput.emit(event);
  }
}
