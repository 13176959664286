import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';

import { IframeComponent } from './iframe/iframe.component';
import { MulticonvisComponent } from './multiconvis.component';

/**
 * Module to display multiConvis analysis for an operation.
 */
@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [IframeComponent, MulticonvisComponent],
  exports: [MulticonvisComponent]
})
export class MulticonvisModule {}
