import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';

/**
 * Component to display shared header across the application (after logging in).
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
  /** Page title is provided by the parent component */
  @Input()
  title: string;
  @Input()
  convo_range: string;

  /** References 'Actions' dropdown menu */
  @ViewChild('dropdownMenu')
  dropdownMenu;

  /** If true, display 'Actions' dropdown button. Otherwise, hide the button. */
  showDropdown = true;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  /**
   * Called after component view is initialized, and hides 'Action' dropdown button
   * if dropdown items are empty.
   */
  ngAfterViewInit() {
    
  }
}
