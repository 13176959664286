import { Component, OnInit, ViewContainerRef, Output } from '@angular/core';
import { Dataset } from '../shared/models/dataset.model';
import { Router } from '@angular/router';
import { ModalDialogService } from 'ngx-modal-dialog';
import { DatasetNewComponent } from './dataset-new/dataset-new.component';
import { DatasetRemoveComponent } from './dataset-remove/dataset-remove.component';
import { EventEmitter } from 'events';
import { environment } from '../../environments/environment.prod';


/**
 * Component to display the list of all datasets.
 */
@Component({
  selector: 'app-datasets',
  templateUrl: './datasets.component.html',
  styleUrls: ['./datasets.component.scss']
})
export class DatasetsComponent implements OnInit {
  /** Datasets fetched from the database */
  datasets: Dataset[];
  /** Selected conversations */
  datasetsSelected = [];

  @Output()
  
  rows: Dataset[];

  rowsSelected = [];
  /** If true, all rows will be selected */
  selectAll = false;

  constructor(
    private router: Router,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef,
  ) {}

  ngOnInit() {
    $("div#loader").hide();
    $("img#hourglass").hide();
  }
  /**
   * Handles datasets select and unselect.
   *
   * @param datasets Datasets selected.
   */
  onDatasetsSelected(datasets: Dataset[]) {
    this.datasetsSelected = datasets;
  }

  /**
   * Handles dataset row click, and navigates to the clicked dataset page.
   */
  onDatasetClick(dataset: Dataset) {
    window.location.href = environment.convexURL + '/' + '?dataset_id=' + dataset.getID();
  }

  /**
   * Handles 'Remove Datasets' button click, and trigger modal to
   * remove datasets from the system.
   */
  onRemoveDatasetsClick() {
    this.modalService.openDialog(this.viewRef, {
      title: 'Remove Datasets',
      childComponent: DatasetRemoveComponent,
      data: {
        datasets: this.datasetsSelected
      }
    });
  }
}
