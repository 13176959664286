import { Component } from '@angular/core';

/**
 * Component to display the most recent Conversations, Datasets, and Operations.
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  constructor() {}
}
