import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { SharedModule } from './shared/shared.module';

import { ConversationsModule } from './conversations/conversations.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DatasetsModule } from './datasets/datasets.module';
import { OperationsModule } from './operations/operations.module';
import { PreLoginModule } from './pre-login/pre-login.module';
import { UserModule } from './user/user.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { ErrorHandlerService } from './shared/services/error-handler.service';
import { ServicesModule } from './shared/services.module';
import { PlotlyChartModule } from './plotly-chart/plotly-chart.module';

/**
 * Application root module.
 */
@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    ServicesModule,

    ConversationsModule,
    DatasetsModule,
    DashboardModule,
    OperationsModule,
    PreLoginModule,
    UserModule,
    PlotlyChartModule, 
  ],
  providers: [
    {
      // Use custom error handler instead of the default
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
