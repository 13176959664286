import { NgModule } from '@angular/core';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { SharedModule } from '../shared/shared.module';

import { ConversationFilterComponent } from './conversation-filter/conversation-filter.component';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { FilterComponent } from './filter.component';
import { OperationsFilterComponent } from './operations-filter/operations-filter.component';

/**
 * Module to display conversation filters or operation filters.
 */
@NgModule({
  imports: [NgxMyDatePickerModule.forRoot(), SharedModule],
  declarations: [
    ConversationFilterComponent,
    DateFilterComponent,
    FilterComponent,
    OperationsFilterComponent
  ],
  exports: [FilterComponent]
})
export class FilterModule {}
