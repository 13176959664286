import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SignupService } from '../../shared/services/signup.service';

/**
 * Component to display the signup form.
 */
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  constructor(private signupService: SignupService) {}

  /**
   * Handles form submission.
   *
   * @param form References submitted form
   */
  onSubmit(form: FormGroup) {
    this.signupService.signup(
      form.value.email,
      form.value.firstname,
      form.value.lastname,
      form.value.username,
      form.value.password
    );
  }
}
