import { Component, OnInit, Input } from '@angular/core';

/**
 * Component to display frequencies data for an operation.
 */
@Component({
  selector: 'app-frequencies',
  templateUrl: './frequencies.component.html',
  styleUrls: ['./frequencies.component.scss']
})
export class FrequenciesComponent implements OnInit {
  /** Unsorted data is provided by the parent component */
  @Input()
  data;
  /** Sorted tokens data to display in table */
  dataSorted = [];
  /** Sorted data keys */
  dataSortedKeys = [];

  /** Map of data keys to titles */
  titles = {
    most_common_tokens: 'Tokens',
    most_common_document_frequencies: 'Document',
    most_common_hashtags: 'Hashtags',
    most_common_terms_ponly: 'Terms Only',
    most_common_bigrams: 'Bigrams',
    most_common_cooccurences: 'Co-occurrences',
    most_common_searched_cooccurences: 'Searched Co-occurrences',
    most_messaging_users: 'Most Messaging Users'
  };
  /** Data keys */
  keys = Object.keys(this.titles);

  constructor() {}

  /**
   * Called after initializing properties.
   */
  ngOnInit() {
    this.getSortedData();
  }

  /**
   * Sort data so that, for each key in this.data,
   * this.dataSorted[i][key] contains the value at this.data[key][i], and
   * this.dataSorted[i][key_freq] contains its frequency
   */
  getSortedData() {
    let i = 0;
    let rowEmpty = false; // True if dataSorted[i] is empty at the end of the loop

    while (!rowEmpty) {
      rowEmpty = true; // Will set to false if a key contains an i-th value

      const obj = {};

      for (const key of this.keys) {
        // E.g., this.data = { most_common_tokens: {ok: 2, hi: 1, ...}}
        // col = {ok: 2, hi:, 1, ...}
        // colKeys = [ok, hi, ...]
        const col = this.data[key];
        const colKeys = Object.keys(col);

        // If col contains an i-th value, set obj[key] and obj[key_freq]
        // Otherwise, set both to empty strings
        if (colKeys[i]) {
          rowEmpty = false;

          // most_common_cooccurrences/bigrams have different format than other keys:
          // e.g., [ ["cow", "jumped", 1], ["cow", "moon", 1], ...]
          const cooccurrenceOrBigram =
            key !== 'most_common_cooccurences' && key !== 'most_common_bigrams';

          obj[key] = cooccurrenceOrBigram
            ? colKeys[i]
            : col[i][0] + ' ' + col[i][1];

          obj[key + '_freq'] = cooccurrenceOrBigram
            ? col[colKeys[i]]
            : col[i][2];
        } else {
          obj[key] = '';
          obj[key + '_freq'] = '';
        }
      }

      if (!rowEmpty) {
        this.dataSorted[i] = obj;
        i++;
      }
    }

    this.dataSortedKeys = Object.keys(this.dataSorted[0]);
  }
}
