import {
  Component,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { ConversationsService } from '../../shared/services/conversations.service';
import { Conversation } from '../../shared/models/conversation.model';
import { IModalDialog } from 'ngx-modal-dialog';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { DatasetsService } from '../../shared/services/datasets.service';
import { UploadEvent, FileSystemFileEntry } from 'ngx-file-drop';

/**
 * Modal to select conversations and add them to a new dataset.
 */
@Component({
  selector: 'app-dataset-new',
  templateUrl: './dataset-new.component.html',
  styleUrls: ['./dataset-new.component.scss']
})
export class DatasetNewComponent implements IModalDialog, OnDestroy {
  /** References the form in the template */
  @ViewChild('form')
  form: NgForm;

  files: File[] = [];

  /** ID of the new dataset is returned in the response after creating the dataset */
  datasetID: number;

  /** Conversations fetched from the database */
  conversations: Conversation[];
  /** Conversations selected for adding */
  conversationIDs: number[];

  /** Subscription to conversationsService.dataScrolled for infinite scrolling conversations */
  dataScrolledSubscription: Subscription;

  constructor(
    private conversationService: ConversationsService,
    private datasetsService: DatasetsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  
  /**
   * Called after initialization.
   *
   * Receives data from parent component and initializes variables.
   *
   * @param reference
   * @param options Contains modal options (including data) from parent component.
   */
  dialogInit() {
    this.getConversations();
    this.files = [];
    // dataChanged is called by this.onScroll()
    // Need to GET more conversations from DB for infinite scrolling
    this.dataScrolledSubscription = this.conversationService.dataScrolled.subscribe(
      () => this.getConversations(true)
    );
  }

  /**
   * Called when modal is destroyed.
   */
  ngOnDestroy() {
    this.dataScrolledSubscription.unsubscribe();
  }

  /**
   * Sets this.conversations to conversations fetched from the database, and update view.
   *
   * @param scrolling Set to true if loading more conversations for infinite scrolling.
   */
  getConversations(scrolling?: boolean) {
    this.conversationService
      .getData(scrolling)
      .subscribe((conversations: Conversation[]) => {
        this.conversations = conversations;
        this.changeDetectorRef.detectChanges();
      });
  }

  /**
   * Handles scrolling to bottom of multiple select input for conversations.
   */
  onScroll() {
    // Notify this component to GET more conversations for infinite scrolling
    this.conversationService.dataScrolled.next();
  }

  /**
   * Adds conversations to a dataset.
   *
   * @param datasetID ID of the dataset
   */
  modifyDataset(datasetID: number) {
    this.datasetsService
      .modifyConversations(
        datasetID,
        this.conversationIDs,
        this.datasetID == null
      )
      .subscribe(() => this.done());
  }

  /**
   * Call after database has been updated with modified dataset.
   */
  done() {
    this.form.reset();
  }

  /**
   * Handles file drag-and-drop event.
   *
   * ngx-file-drop documentation: https://www.npmjs.com/package/ngx-file-drop
   */
  onFileDrop(event: UploadEvent) {
    event.files.forEach((file, index) => {
      if (file.fileEntry.isFile) {
        const fileEntry = file.fileEntry as FileSystemFileEntry;

        fileEntry.file((f: File) => {
          this.files.push(f);

          // After adding all files, manually trigger change detection.
          // Otherwise, template does not detect changes to this.files.
          if (index === event.files.length - 1) {
            this.changeDetectorRef.detectChanges();
          }
        });
      }
    });
  }
  onFileChange(event: any) {
    if(this.files.length < 1){
      this.files.push(...event.target.files);
    }
  }

  onUpload() {
    let filesUploaded = 0;
    this.files.forEach(file => {
      const formData = new FormData();
      formData.append('dataset_file', file, file.name);
      formData.append('dataset_name', this.form.value.datasetName);
      formData.append('dataset_description', this.form.value.datasetDescription);
      formData.append('dataset_healthcentre', this.form.value.datasetHealthCentre);
      this.datasetsService.createDataset(formData).subscribe((datasetID: number) => {
        // After all files are uploaded, notify DatatableComponent to re-fetch conversations and update view
        if (++filesUploaded === this.files.length) {
          this.datasetsService.dataChanged.next();
        }
        // this.modifyDataset(datasetID)
      }); 
    });
  }

}
