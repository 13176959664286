import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { HttpService } from './http.service';

/**
 * Service to interact with the server and perform operations to reset password.
 */
@Injectable()
export class PasswordResetService {
  /** URL to POST email that will password reset link */
  forgotPasswordUrl = '/forgot_password';
  /** URL to POST new password */
  resetPasswordUrl = '/reset_password';

  constructor(
    private notificationService: NotificationService,
    private httpService: HttpService
  ) {}

  /**
   * Sends email with password reset link and displays notification upon success or error.
   *
   * @param email Address to receive email
   */
  sendEmail(email: string) {
    const data = {
      email: email
    };

    const url = this.httpService.getUrl() + this.forgotPasswordUrl;
    this.httpService.sendPost(url, data).subscribe(
      response => {
        if (response['status'] === 'success') {
          this.notificationService.success(
            'Thank you. Email sent to ' + data['email'] + ' !'
          );
        } else {
          throw Error(response['error']);
        }
      },

      error => {
        throw error;
      }
    );
  }

  /**
   * Resets password and displays notification upon success or error.
   *
   * @param resetKey Reset key contained in the email's password reset link
   * @param newPassword New password
   */
  resetPassword(resetKey: string, newPassword: string) {
    const data = {
      reset_key: resetKey,
      new_password: newPassword
    };

    const url = this.httpService.getUrl() + this.resetPasswordUrl;
    this.httpService.sendPost(url, data).subscribe(
      response => {
        if (response['status'] === 'success') {
          this.notificationService.success('Password changed successfully!');
        } else {
          throw Error(response['error']);
        }
      },

      error => {
        throw error;
      }
    );
  }
}
