import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { User } from '../shared/models/user.model';
import { UserService } from '../shared/services/user.service';
import { ModalDialogService } from 'ngx-modal-dialog';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeEmailComponent } from './change-email/change-email.component';
import { UserTopicsComponent } from './user-topics/user-topics.component';
import { Subscription } from 'rxjs';
import { UserRemoveComponent } from './user-remove/user-remove.component';
import { PurgeDataComponent } from './purge-data/purge-data.component';

/**
 * Component to display user information.
 */
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  listArr = [];
  inputTxt = '';
  /** Current user */
  user: User;

  /** User email */
  // Need separate variable for email, otherwise view will not be updated after changing email.
  // Alternativeto using separate variable: call changeDetectorRef.detectChanges() after changing email.
  email: string;

  /** Subscription to userService.dataChanged for updating view after data modifications */
  dataChangedSubscription: Subscription;

  constructor(
    private userService: UserService,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef
  ) {}

  /**
   * Called after initializing variables.
   */
  ngOnInit() {
    this.getUser();
    this.dataChangedSubscription = this.userService.dataChanged.subscribe(() => {
      this.getUser();
    }
    );
  }

  /**
   * Called when component is destroyed.
   */
  ngOnDestroy() {
    this.dataChangedSubscription.unsubscribe();
  }

  /**
   * Set user.
   */
  getUser() {
    const storedUserID = JSON.parse(localStorage.getItem('currentUser'));
    this.userService.getUser(storedUserID).subscribe((user: User) => {
      this.email = user.getEmail();
      this.user = user;
      this.listArr = user.getUserTopics();
    });
  }

  /**
   * Add/Remove topic to List
   */
  addUserTopicToList(){
    if(this.inputTxt != ''){
      this.userService.addUserTopic(this.inputTxt);
    }
  }

  removeUserTopicFromList(index : number){
    // this.listArr.splice(index,1);
    this.userService.removeUserTopic(this.listArr[index]);
  }

  onAddUserTopicClick() {
    this.modalService.openDialog(this.viewRef, {
      title: 'Add User Topic',
      childComponent: UserTopicsComponent
    });
  }

  /**
   * Handles 'Change Email' button click, and triggers modal to display the email change form.
   */
  onChangeEmailClick() {
    this.modalService.openDialog(this.viewRef, {
      title: 'Change Email',
      childComponent: ChangeEmailComponent
    });
  }

  /**
   * Handles 'Change Password' button click, and triggers modal to display the password change form.
   */
  onChangePasswordClick() {
    this.modalService.openDialog(this.viewRef, {
      title: 'Change Password',
      childComponent: ChangePasswordComponent
    });
  }

  /**
   * Handles 'Delete Account' button click, and triggers modal to display the password confirmation form.
   */
  onDeleteClick() {
    this.modalService.openDialog(this.viewRef, {
      title: 'Delete Account',
      childComponent: UserRemoveComponent
    });
  }

  /**
   * Handles 'Clear Data' button click, and triggers modal to display the password confirmation form.
   */
  onClearDataClick() {
    this.modalService.openDialog(this.viewRef, {
      title: 'Clear Data',
      childComponent: PurgeDataComponent
    });
  }
}
