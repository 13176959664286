import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { AnalysisModule } from './analysis/analysis.module';
import { DatatableModule } from '../datatable/datatable.module';
import { FilterModule } from '../filter/filter.module';

import { OperationsDatasetNewComponent } from '../datasets/operations-dataset-new/operations-dataset-new.component';
import { OperationsComponent } from './operations.component';
import { OperationNewComponent } from './operation-new/operation-new.component';
import { OperationComponent } from './operation/operation.component';

import { FilterService } from '../shared/services/filter.service';

/**
 * Module to display a filtered list of either all operations or dataset operations, and
 * in response to user interactions, display modals to:
 * display an operation's information, and if in progress, obtain the current status or cancel the operation;
 * select a dataset and create an operation;
 * display the analysis overview for an operation, and either
 * frequencies analysis, sentiments analysis, or multiConvis analysis.
 */
@NgModule({
  imports: [SharedModule, AnalysisModule, DatatableModule, FilterModule],
  declarations: [
    OperationComponent,
    OperationNewComponent,
    OperationsComponent,
    OperationsDatasetNewComponent
  ],
  providers: [FilterService],
  exports: [OperationsComponent],
  entryComponents: [
    OperationComponent,
    OperationNewComponent,
    OperationsDatasetNewComponent
  ]
})
export class OperationsModule {}
