import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreLoginService } from '../shared/services/pre-login.service';

/**
 * Component to display the corresponding form for each pre-login route.
 */
@Component({
  selector: 'app-pre-login',
  templateUrl: './pre-login.component.html',
  styleUrls: ['./pre-login.component.scss']
})
export class PreLoginComponent implements OnInit {
  /** Defines the content to display for each pre-login route */
  // For example:
  // '/STAT/login': {
  //   title: 'Login',
  //   fields: [this.formFields.username, this.formFields.password],
  //   links: ['/STAT/signup', '/STAT/password-reset'],
  // },
  routes;

  constructor(
    public router: Router,
    private preLoginService: PreLoginService
  ) {}

  /**
   * Called after initializing properties.
   */
  ngOnInit() {
    this.routes = this.preLoginService.getRoutes();
  }
}
