import { Component, OnInit } from '@angular/core';
import { OperationsService } from '../../shared/services/operations.service';
import { ActivatedRoute } from '@angular/router';

/**
 * Component to display the analysis overview for an operation, and either:
 * frequencies analysis, sentiments analysis, or multiConvis analysis.
 */
@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements OnInit {
  /** Operation to display */
  operationID;

  /** Overview data */
  overviewData = [];
  /** Map of overview data keys to titles */
  titles = {
    start_date: 'Start Date',
    end_date: 'End Date',
    dates_searched_appeared: 'Dates Searched Appeared',
    unique_user_count: 'Number of Unique Users'
  };
  /** Overview data keys */
  keys = Object.keys(this.titles);

  /** Frequencies data */
  frequencies;
  /** Sentiments data */
  sentiments;

  /** List of possible pages to display */
  pages = ['Frequencies', 'Sentiments', 'Multiconvis'];
  /** Page displayed */
  page;
  /** Pages not displayed */
  pagesInactive;

  constructor(
    private operationsService: OperationsService,
    private route: ActivatedRoute
  ) {}

  /**
   * Called after initializing properties.
   */
  ngOnInit() {
    this.operationID = this.route.snapshot.params['id'];
    this.switchPage('Frequencies');
    this.getData();
  }

  /**
   * Set overviewData, frequencies, and sentiments.
   */
  getData() {
    // Get URLs to output files, then get output files
    this.operationsService.getOutput(this.operationID).subscribe(data => {
      for (const d of data) {
        this.getOutputFile(d['output_url']);
      }
    });
  }

  /**
   * Get the output file at the given URL, and set either:
   * overviewData and frequencies, or sentiments.
   *
   * @param url URL whose contents to read
   */
  getOutputFile(url: string) {
    this.operationsService.getOutputFile(url).subscribe(data => {
      // Two output files: sentiments file, frequencies and overview file
      if (data['anger_score'] !== undefined) {
        this.sentiments = data;
      } else if (data['start_date']) {
        this.frequencies = data;
        this.getOverviewData();
      }
    });
  }

  /**
   * Set overviewData.
   */
  getOverviewData() {
    for (const key of this.keys) {
      this.overviewData.push({
        name: this.titles[key],
        value: this.frequencies[key]
      });
    }
  }

  /**
   * Handles page switch.
   *
   * @param page Page to switch to
   */
  switchPage(page: string) {
    this.page = page;
    this.pagesInactive = this.pages.filter(item => this.page !== item);
  }
}
