import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

/**
 * Component to display operations filter
 */
@Component({
  selector: 'app-operations-filter',
  templateUrl: './operations-filter.component.html',
  styleUrls: ['./operations-filter.component.scss']
})
export class OperationsFilterComponent {
  /** References the form in the template */
  @ViewChild('filter')
  filterForm: NgForm;

  /** Notifies parent component after submitting filter */
  @Output()
  filterInput = new EventEmitter<{}>();

  /** List of possible operation statuses */
  statuses = ['All', 'Creating', 'Enqueued', 'Cancelled', 'Failed', 'Complete'];
  /** Status filter */
  status = 'All';

  /** Date filters */
  dateCols = {
    created_timestamp: { title: 'Date Created', min: null, max: null }
  };

  constructor() {}

  /**
   * Handles status filter change.
   *
   * @param status Status to filter
   */
  onSwitchStatus(status: string) {
    this.status = status;
  }

  /**
   * Handles date-picker changes.
   *
   * @param event An Object { minMax: string, dateFormatted: string }, where minMax is either 'min' or max'
   * @param key A key in dateCols
   */
  onDateChanged(event: any, key: any) {
    this.dateCols[key][event.minMax] = event.dateFormatted;
  }

  /**
   * Handles form submission, and passes submitted filter to parent component.
   */
  onSubmit() {
    this.filterInput.emit({
      statusCol: this.status.toLowerCase(),
      dateCols: this.dateCols
    });
  }

  /**
   * Handles dropdown form click, and stops event propagation.
   *
   * @param event Click event
   */
  onFormClick(event) {
    event.stopPropagation(); // Otherwise dropdown will close
  }
}
