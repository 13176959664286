import { Component, OnInit, HostListener } from '@angular/core';
import { LoginService } from '../shared/auth/login.service';
import { UserService } from '../shared/services/user.service';
import { User } from '../shared/models/user.model';
import { Router } from '@angular/router';

/**
 * Component to display shared sidebar navigation and wrapper for router outlet (after logging in).
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  /** If true, display full sidebar navigation. Otherwise, display sidebar icons. */
  sidebar = true;

  /** List of sidebar navigation items that defines the URL, title, and icon for each item */
  sidebarItems = [
    // - Commented for now, remove when decision to remove convo is finalized -
    //{ url: '/dashboard', title: 'Dashboard', icon: 'fa-dashboard' },
    //{ url: '/conversations', title: 'File Upload', icon: 'fa-comment' },
    { url: '/datasets', title: 'Datasets', icon: 'fa-database', id: 'datasets-nav'},
    // { url: '/datasets-overview', title: 'Overview', icon: 'fa-eye', id: 'overview-nav' }
    // { url: '/operations', title: 'Operations', icon: 'fa-eye' }
  ];

  overviewItems = [
    { target: 'overview', title: 'Overview', icon: 'fa-eye', id: 'overview-nav', url: '/datasets-overview'},
    { target: 'topics', title: 'Topics', icon: 'fa-list-alt', id: 'basicnlp-nav'},
    { target: 'needs', title: 'Patient Needs', icon: 'fa-stethoscope', id: 'need-nav' },
    { target: 'explore', title: 'Explorer', icon: 'fa-globe', id: 'explore-nav' }
  ]

  isDatasetsPage: boolean;

  /** Current user */
  user: User;

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private router: Router
  ) {}

  /**
   * Called after initializing properties.
   */
  ngOnInit() {
    // Set this.user to current user
    const storedUserID = JSON.parse(localStorage.getItem('currentUser'));
    
    this.userService
      .getUser(storedUserID)
      .subscribe((user: User) => (this.user = user));
    
  }

  /**
   * Handles sidebar toggle button click.
   */
  toggleSidebar() {
    this.sidebar = !this.sidebar;
  }

  /**
   * Handles 'Logout' button click.
   */
  onLogout() {
    this.loginService.onLogout();
  }

  scrollToElement($element) {
    document.getElementById($element).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  isScrolledIntoView(element) {
    var elementObject = document.getElementById(element);
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elementObject).offset().top;
    var elemBottom = elemTop + $(elementObject).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  }

  inViewport(element) {
    var el = document.getElementById(element);
    var r, html;
    if ( !el || 1 !== el.nodeType ) { return false; }
    html = document.documentElement;
    r = el.getBoundingClientRect();
    
    return ( !!r 
      && r.bottom >= 0 
      && r.right >= 0 
      && r.top <= html.clientHeight 
      && r.left <= html.clientWidth 
    );
  }

  routeToSandbox() {
    window.location.href = "http://convis-sandbox-demo.eba-2jfah7bq.ca-central-1.elasticbeanstalk.com/"
  }
}
