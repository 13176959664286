import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrequenciesComponent } from './frequencies.component';

/**
 * Module to display frequencies data for an operation.
 */
@NgModule({
  imports: [CommonModule],
  declarations: [FrequenciesComponent],
  exports: [FrequenciesComponent]
})
export class FrequenciesModule {}
