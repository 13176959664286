import { Component, OnInit, ComponentRef } from '@angular/core';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';
import { Dataset } from '../../shared/models/dataset.model';
import { DatasetsService } from '../../shared/services/datasets.service';

/**
 * Modal to remove selected datasets from the system.
 */
@Component({
  selector: 'app-dataset-remove',
  templateUrl: './dataset-remove.component.html',
  styleUrls: ['./dataset-remove.component.scss']
})
export class DatasetRemoveComponent implements IModalDialog {
  /** Datasets to remove are provided by the parent component */
  datasets: Dataset[];

  constructor(private datasetsService: DatasetsService) {}

  /**
   * Called after initialization.
   *
   * Receives data from parent component and initializes variables.
   *
   * @param reference
   * @param options Contains modal options (including data) from parent component.
   */
  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<string>>
  ) {
    this.datasets = options.data['datasets'];
  }

  /**
   * Handles confirmation.
   *
   * Removes conversations from the system and updates view.
   */
  onConfirm() {
    const datasetIDs = this.datasets.map(dataset => dataset.getID());

    let datasetsRemoved = 0;
    datasetIDs.forEach((datasetID, index) => {
      this.datasetsService.removeDatasets(datasetID).subscribe(() => {
        // After all selected conversations are removed,
        // notify DatatableComponent to re-fetch conversations and update view
        if (++datasetsRemoved === datasetIDs.length) {
          this.datasetsService.dataChanged.next();
        }
      });
    });
  }
}
