import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConversationsService } from './services/conversations.service';
import { DatasetsService } from './services/datasets.service';
import { HttpService } from './services/http.service';
import { LoginService } from './auth/login.service';
import { NotificationService } from './services/notification.service';
import { OperationsService } from './services/operations.service';
import { PreLoginService } from './services/pre-login.service';
import { UserService } from './services/user.service';

/**
 * Module containing shared services.
 */
@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    ConversationsService,
    DatasetsService,
    HttpService,
    LoginService,
    NotificationService,
    OperationsService,
    PreLoginService,
    UserService
  ]
})
export class ServicesModule {}
