import { Component, Input } from '@angular/core';
import { PlotlyChartService } from '../shared/services/plotly-chart.service';
import { PlotlyModule } from 'angular-plotly.js';
import { Dataset } from '../shared/models/dataset.model';
import { DatasetsService } from '../shared/services/datasets.service';
import { ActivatedRoute } from '@angular/router';

declare var Plotly: any;

@Component({
  selector: 'app-plotly-chart',
  templateUrl: './plotly-chart.component.html',
  styleUrls: ['./plotly-chart.component.scss']
})
export class PlotlyChartComponent {
  @Input()
  title: string;
  @Input()
  chartId: string;
  @Input()
  showFilter: boolean;

  dataset: Dataset;
  datasetID: number;

  public graph = {
    data: [{}],
    layout: {
      width: 700,
      height: 550,
      grid: {},
      title: {  
        text: '',
        font: {
          family: 'Arial',
          size: 24,
        },
        xref: 'paper',
        x: 5,
      },
      legend:{
        orientation: "h",
        x: 0.6,
        y: 1.2,
        sort: false,
      },
    },
    config: {
      displaylogo: false,
      modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d', 'resetScale2d', 'toggleSpikelines', 'hoverClosestCartesian', 'hoverCompareCartesian']
    },
  };

  categories: string[] =[
    'Gender', 'Age'
  ]

  constructor(
    private plotlyChartService: PlotlyChartService,
    private datasetsService: DatasetsService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.datasetID = this.route.snapshot.params['id']
    if (this.title == 'Gender/Age Distribution'){
      this.plotlyChartService.dataToView = 'GenderDistribution';
      this.makeChart("Gender");
    }
    if (this.title == 'Conversational Topics'){
      this.plotlyChartService.dataToView = 'Conversational Topics';
      this.makeChart('Conversational Topics')
    }
    if (this.title == 'Need Types') {
      this.plotlyChartService.dataToView = 'Need Types';
      this.makeChart('Need Types')
    }
  }

  makeChart(chartName: string){
    this.datasetsService.getDataset(this.datasetID).subscribe((dataset: Dataset) => {
      if (this.title == 'Gender/Age Distribution'){
        var genderData = {
          values: [
            dataset.getMaleCount(), 
            dataset.getFemaleCount(), 
            dataset.getXCount()
          ],
          labels: this.plotlyChartService.genderLabels,
          marker: {
            colors: ['#F3565D','#FB9D9A', '#FDCCAD'],
          },
          textinfo: 'label+text+value+percent',
          type: 'pie',
          hoverinfo: 'value',
          hole: .45,
          opacity: 0.8,
          domain: {
            row: 1,
            column: 1
          },
          sort: false,
        }

        let age_groups = Object.values(dataset.getAgeGroup())
        var ageData = {
          y: age_groups,
          x: this.plotlyChartService.ageLabels,
          type: 'bar',
          orientation: 'v',
          sort: false,
          text: age_groups.map(String),
          textposition: 'auto',
          hoverinfo: 'y',
          showlegend: false,
          opacity: 0.8,
          marker: {
            color: ['#99E1D9', '#A8DADC', '#70ABAF', '#457B9D', '#32292F'],
          }
        }
          
        this.graph.layout.width = 800;
        this.graph.layout.height = 475;
        this.graph.layout.grid = {rows: 1, columns: 2},
        this.graph.data = [genderData, ageData];
      }

      if (this.title == 'Conversational Topics'){
        var topicsData = [{
          y: this.plotlyChartService.topicLabels,
          x: [20, 14, 23, 12, 18, 29, 13, 20, 25, 5, 7, 10, 18, 29, 13, 20, 17, 3, 1, 15],
          type: 'bar',
          orientation: 'h',
          sort: false,
          text: [20, 14, 23, 12, 18, 29, 13, 20, 25, 5, 7, 10, 18, 29, 13, 20, 17, 3, 1, 15].map(String),
          textposition: 'auto',
          hoverinfo: 'x',
          marker: {
            color: ['#1A3D5A', '#FFAD00', '#C10001', '#750000', '#472A5E', 
            '#8A1E42', '#FFC102', '#F88600', '#AD3B58', '#FF3F62', 
            '#C44165', '#DBC197', '#A6E0B9', '#00AEA8', '#f5efef', 
            '#ff758c', '#c79081', '#596164', '#434343', '#93a5cf',
            '#B8E1F3', '#179AA8', '#AAD356', '#F9C90B', '#F35744'],
            opacity: 0.8,
            size: 20,
          }
        }];

        if(chartName == 'Conversational Topics'){
          this.graph.data = topicsData;
          var myPlot = document.getElementById(this.chartId);
          // this.graph.layout.title = this.plotlyChartService.topicsTitle;
        }
      }

      if (this.title == 'Need Types') {
        var needFrequency = JSON.parse(dataset.getNeedTypes())
        var needArray = [ needFrequency['physical'], needFrequency['emotional'], needFrequency['social'], needFrequency['health_info'] ]
        
        var needData = [{
          y: this.plotlyChartService.needLabels,
          x: needArray,
          type: 'bar',
          orientation: 'h',
          sort: false,
          text: needArray.map(String),
          textposition: 'auto',
          hoverinfo: 'none',
          marker: {
            color: ['#ED553B', '#F6D55C', '#20639B', '#3CAEA3' ],
            opacity: 1,
          }
        }];

        if(chartName == 'Need Types'){
          this.graph.data = needData;
          var myPlot = document.getElementById(this.chartId);
          // this.graph.layout.title = this.plotlyChartService.needTitle;
        }
      }
    });
  }

  onButtonGroupClick($event){
    let clickedElement = $event.target || $event.srcElement;
    
    if(clickedElement.nodeName === "BUTTON") {
      let isCertainButtonAlreadyActive = clickedElement.parentElement.querySelector(".active");
      // if a Button already has Class: .active
      this.makeChart(clickedElement.innerText);
      if(isCertainButtonAlreadyActive) {
        isCertainButtonAlreadyActive.classList.remove("active");
      }
      clickedElement.className += " active";
    }
  }
}

