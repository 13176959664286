import { Injectable } from '@angular/core';
import { Dataset } from '../models/dataset.model';
import { HttpService } from './http.service';
import { UserService } from './user.service';
import { Conversation } from '../models/conversation.model';
import { ConversationsService } from './conversations.service';
import { Subject, Observable } from 'rxjs';
import { NotificationService } from './notification.service';
import { Operation } from '../models/operation.model';
import { OperationsService } from './operations.service';
import { map, catchError } from 'rxjs/operators';

/**
 * Service to interact with the server and perform operations to:
 * get datasets;
 * create a dataset;
 * get and edit information about a dataset;
 * get and edit conversations in a dataset.
 */
@Injectable()
export class DatasetsService {
  /** Datasets fetched from the database */
  datasets: Dataset[] = [];
  operations: Operation[] = [];
  /** Conversations belonging to a dataset */
  datasetConversations: Conversation[] = [];
  /** Conversations belonging to a dataset */
  datasetOperations: Operation[] = [];

  /** Parent route of all dataset endpoints */
  datasetsUrl = '/dataset';
  /** Parent route of all nlp explorer endpoints */
  nlpExplorerUrl = '/nlp_explorer';

  /** Url to GET a list of datasets */
  listDatasetsUrl = this.datasetsUrl + '/list';
  /** Url to POST a dataset */
  createDatasetUrl = this.datasetsUrl + '/create';
  /** Url to POST dataset edits */
  editDatasetUrl = this.datasetsUrl + '/edit';
  /** Url to GET information about a dataset */
  datasetInfoUrl = this.datasetsUrl + '/dataset_info/';
  /** Url to GET a list of conversations belonging to a dataset */
  listConversationsUrl = this.datasetsUrl + '/list_conversations';
  /** Url to POST a list of conversations to add to a dataset */
  addConversationsUrl = this.datasetsUrl + '/add_convos';
  /** Url to DELETE a list of conversations from a dataset */
  removeConversationsUrl = this.datasetsUrl + '/remove_convos';
  /** Url to DELETE a dataset from the system */
  removeDatasetUrl = this.datasetsUrl + '/remove';

  /** Url to POST an preparation operation from a dataset for Conversation Explorer*/
  prepareExplorerUrl = this.nlpExplorerUrl + '/prepare';

  /** Url to GET a list of clinics */
  clinicUrl = this.nlpExplorerUrl +'/clinic';
  /** Url to POST a update and filtered datasets */
  filterDatasetsUrl = this.nlpExplorerUrl + '/upload_filtered_dataset';
  
  /** Url to GET a list of operations belonging to a dataset */
  listOperationsUrl = '/nlp/list_tasks_in_dataset/';
  /** Url to POST an operation from a dataset */
  createOperationUrl = '/nlp/create_from_dataset';

  /** ID of last dataset fetched (for infinite scrolling) */
  lastDatasetID: number;
  // ID of last conversation in a dataset fetched (for infinite scrolling)
  lastConversationID: number;
  /** Number of responses to fetch */
  numResponses = 15;

  /** Call after datasets in the database have been modified */
  dataChanged = new Subject();
  /** Call after scrolling to the bottom of a datasets list */
  dataScrolled = new Subject();
  /** Call after dataset details in the database have been modified */
  datasetChanged = new Subject();

  /** Map of Dataset keys to titles */
  datasetTitles = {
    dataset_name: 'Name',
    dataset_description: 'Description',
    dataset_healthcentre: 'Health Centre',
    created_timestamp: 'Date Uploaded',
    convo_earliest_timestamp: 'Start Date',
    convo_latest_timestamp: 'End Date',
    conversation_count: 'Conversation Count',
    // operation_status: 'Operation Status'
  };

  constructor(
    private httpService: HttpService,
    private userService: UserService,
    private conversationsService: ConversationsService,
    private operationsService: OperationsService,
    private notificationService: NotificationService
  ) {}

  /**
   * Returns an Observable that returns a list of all Datasets.
   *
   * @param scrolling If false, returns most recent datasets.
   * Otherwise, returns datasets uploaded before lastDatasetID and current datasets.
   * @param numResponses Number of responses to return
   */
  getData(scrolling: boolean, numResponses?: number): Observable<Dataset[]> {
    const url = this.httpService.getUrl() + this.listDatasetsUrl;

    const params = {
      user_id: this.userService.getUserId(),
      num_res: numResponses ? numResponses : this.numResponses
    };

    if (scrolling) {
      params['last_id'] = this.lastDatasetID;
    }
    
    return this.httpService.sendGet(url, params).pipe(
      map(response => {
        if (response['status'] && response['status'] === 'failed') {
          throw Error(response['details']);
        }

        if (!scrolling) {
          // Most recent datasets
          this.datasets = [];
          this.datasets = this.getDatasetsList(response['dataset_list']);
        } else {
          // Current datasets + newly loaded datasets
          this.datasets.push(...this.getDatasetsList(response['dataset_list']));
        }
        // Set this.lastDatasetID
        if (this.datasets.length) {
          this.lastDatasetID = this.datasets[this.datasets.length - 1].getID();
        }

        return this.datasets.slice();
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  getClinics(userId: string, datasetId: string): Observable<any[]> {
    const url = this.httpService.getUrl() + this.clinicUrl;
    const params = {
      user_id: userId,
      datasetId: datasetId
    };

    return this.httpService.sendGet(url, params).pipe(
      map(response => {
        if (response['status'] && response['status'] === 'failed') {
          throw Error(response['details']);
        }

        return response['clinics'];
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  filterDatasets(clinicNames, startDate, endDate, datasetId, datasetName): Observable<any[]> {
    const filterParams = {
      clinicNames: clinicNames,
      startDate: startDate,
      endDate: endDate,
      datasetId: datasetId,
      datasetName: datasetName
    };
    const url = this.httpService.getUrl() + this.filterDatasetsUrl;
    $("div#loader").show();
    $("img#hourglass").show();
    return this.httpService.sendPost(url, filterParams).pipe(
      map(response => {
        $("div#loader").hide();
        $("img#hourglass").hide(); 
        if (response['status'] === 'success') {    
          this.notificationService.success('Filtered Dataset created!');
          location.reload();
        } else if (response['status'] === 'invalid') {
          this.notificationService.error('No qualified conversations found! Please change the filter criteria.');
        } else {
          this.notificationService.error("Failed to create filtered dataset! "+response['details']);
          throw Error(response['details']);
        }

        return response['dataset_id'];
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Return a Dataset.
   *
   * @param dataset Dataset from GET result
   */
  getDatasetModel(dataset: any): Dataset {
    console.log("dataset:",dataset);
    return new Dataset(
      dataset.dataset_id,
      dataset.dataset_name,
      dataset.dataset_description,
      dataset.dataset_healthcentre,
      this.getFormattedDateandTime(dataset.created_timestamp),
      dataset.male_count,
      dataset.female_count,
      dataset.x_count,
      dataset.age_median,
      dataset.age_range,
      dataset.age_distribution,
      dataset.patient_count,
      this.getFormattedDate(dataset.convo_earliest_timestamp),
      this.getFormattedDate(dataset.convo_latest_timestamp),
      dataset.num_msgs,
      dataset.file_name,
      dataset.conversation_count,
      dataset.num_conversations,
      dataset.age_group,
      dataset.word_cloud_data,
      dataset.provider_count,
      dataset.operation_status,
      dataset.task_id,
      dataset.need_types,
      dataset.med_topics,
      dataset.wc_med_physical,
      dataset.wc_med_emotional,
      dataset.wc_med_diagnosis,
      dataset.wc_med_laboratory,
      dataset.wc_med_imaging,
      dataset.wc_med_prescription,
      dataset.wc_med_alternative,
      dataset.wc_med_device,
      dataset.wc_med_therapy,
      dataset.wc_med_counselling,
      dataset.wc_med_diet,
      dataset.wc_med_exercise,
      dataset.wc_med_substance,
      dataset.wc_med_housing,
      dataset.wc_med_work,
      dataset.wc_med_social_service,
      dataset.wc_med_ff,
      dataset.wc_med_cultural,
      dataset.wc_med_outpatient,
      dataset.wc_med_hospitalization,
      dataset.wc_med_technical,
      dataset.wc_nt_physical,
      dataset.wc_nt_emotional,
      dataset.wc_nt_social,
      dataset.wc_nt_health_info,
    );
  }

  /**
   * Returns a formatted date string.
   *
   * @param date Date string from GET result
   */
  getFormattedDateandTime(date: string): string {
    Date.prototype.toISOString = function() {
      var tzo = -this.getTimezoneOffset(),
          dif = tzo >= 0 ? '+' : '-',
          pad = function(num) {
              var norm = Math.floor(Math.abs(num));
              return (norm < 10 ? '0' : '') + norm;
          };
      return this.getFullYear() +
          '-' + pad(this.getMonth() + 1) +
          '-' + pad(this.getDate()) +
          'T' + pad(this.getHours()) +
          ':' + pad(this.getMinutes()) +
          ':' + pad(this.getSeconds()) +
          dif + pad(tzo / 60) +
          ':' + pad(tzo % 60);
  }
    var raw_date = new Date(date).toISOString().slice(0,16).replace("T", " ");
    // raw_date = raw_date.slice(0,10) + " " + raw_date.slice(11,16)
    return raw_date;
  }

  getFormattedDate(date: string): string {
    // return new Date(date).toLocaleDateString();
    console.log("received date from db:",date);
     if (!date) return null;
   
     try {
       const parsedDate = new Date(date);
       
       // Check if date is valid
       if (isNaN(parsedDate.getTime())) {
         console.warn('Invalid date:', parsedDate);
         return null;
       }
       
       const year = parsedDate.getFullYear();
       const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
       const day = String(parsedDate.getDate()).padStart(2, '0');
       
       const hours = String(parsedDate.getHours()).padStart(2, '0');
       const minutes = String(parsedDate.getMinutes()).padStart(2, '0');
       console.log('after parsed date:',`${year}-${month}-${day} ${hours}:${minutes}`);
       
       return `${year}-${month}-${day} ${hours}:${minutes}`;
     } catch (e) {
       console.error('Error formatting date:', e);
       return null;
     }
  }

  /**
   * Returns a list of Datasets.
   *
   * @param datasets Datasets list from GET result
   */
  getDatasetsList(datasets: any): Dataset[] {
    if (!datasets) {
      return [];
    }
    const list: Dataset[] = [];
    for (const dataset of datasets) {
      if(!dataset.operation_status){
        dataset.operation_status = "-"
      }
      list.push(this.getDatasetModel(dataset));
    }
    return list;
  }

  /**
   * Returns an Observable that returns a Dataset.
   *
   * @param datasetID ID of the dataset to return
   */
  getDataset(datasetID: number): Observable<Dataset> {
    const url = this.httpService.getUrl() + this.datasetInfoUrl + datasetID;
    console.log("url:",url);
    return this.httpService.sendGet(url).pipe(
      map(response => this.getDatasetModel(response['dataset'])),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Returns an Observable that creates a dataset and returns its ID.
   *
   * @param name Name of the new dataset
   * @param description Description of the new dataset
   */
  createDataset(formData: FormData): Observable<number> {
    $("div#loader").show();
    $("img#hourglass").show();
    const url = this.httpService.getUrl() + this.createDatasetUrl;
    return this.httpService.sendPost(url, formData).pipe(
      map(response => {
        $("div#loader").hide();
        $("img#hourglass").hide();
        if (response['status'] === 'success') {
          location.reload();
          return response['dataset_id'];
        } else {
          throw Error(response['details']);
        }
      }),

      catchError(error => {
        $("div#loader").hide();
        $("img#hourglass").hide();
        throw error;
      })
    );
  }

  /**
   * Returns an Observable that modifies conversations in a dataset and
   * displays notification upon success or error.
   *
   * @param datasetID Dataset to modify
   * @param convoIDs Conversations to add or remove
   * @param add If true, add conversations. Otherwise, remove conversations
   */
  modifyConversations(
    datasetID: number,
    convoIDs: number[],
    add: boolean
  ): Observable<void> {
    const data = {
      dataset_id: datasetID,
      convo_ids: convoIDs
    };

    const url =
      this.httpService.getUrl() +
      (add ? this.addConversationsUrl : this.removeConversationsUrl);

    const request = add
      ? this.httpService.sendPost(url, data)
      : this.httpService.sendDelete(url, data);

    return request.pipe(
      map(response => {
        if (response['status'] !== 'error') {
          this.notificationService.success(
            'Conversation(s) ' + (add ? 'added!' : 'removed!')
          );

          // If in datasets view, notify DatasetsComponent to refetch datasets and update view
          this.dataChanged.next();

          // If in dataset conversations view, notify DatatableComponent to re-fetch dataset conversations and update view
          this.conversationsService.dataChanged.next();
        } else {
          throw Error(response['details']);
        }
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Edits a dataset and displays notification upon success or error.
   *
   * @param data An Object { dataset_id: 1, edits: { dataset_name: name, dataset_description: description } }
   */
  editDataset(data: {}) {
    const url = this.httpService.getUrl() + this.editDatasetUrl;
    this.httpService.sendPost(url, data).subscribe(
      response => {
        if (response['status'] === 'success') {     
          this.notificationService.success('Dataset updated!');
          this.datasetChanged.next();
          location.reload();
        } else {
          throw Error(response['details']);
        }
      },

      error => {
        throw error;
      }
    );
  }

  /**
   * Returns an Observable that returns a list of Conversations belonging to a dataset.
   *
   * @param datasetID Return conversations belonging to this dataset
   * @param scrolling If false, returns most recent conversations.
   * Otherwise, returns conversations uploaded before lastConversationID and current conversations.
   * @param numResponses Number of responses to return
   */
  listConversations(
    datasetID: number,
    scrolling: boolean
  ): Observable<Conversation[]> {
    const url = this.httpService.getUrl() + this.listConversationsUrl;

    const params = {
      dataset_id: datasetID,
      num_res: this.numResponses
    };

    if (scrolling) {
      params['index'] = this.lastConversationID;
    }

    return this.httpService.sendGet(url, params).pipe(
      map(response => {
        const conversationList = response['conversation_list'];
        if (!scrolling) {
          this.datasetConversations = [];
          this.datasetConversations = this.conversationsService.getConversationsList(
            conversationList
          );
        } else {
          this.datasetConversations.push(
            ...this.conversationsService.getConversationsList(conversationList)
          );
        }

        if (conversationList.length) {
          this.lastConversationID =
            conversationList[conversationList.length - 1].index;
        }

        return this.datasetConversations.slice();
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Creates an operation from a dataset and displays notification upon success or error.
   *
   * @param datasetID Create an operation from this dataset
   */
  createOperation(
    datasetID: number
  ) {
    const data = {
      dataset_id: datasetID
    };
    $("div#loader").show();
    $("img#hourglass").show();
    const url = this.httpService.getUrl() + this.prepareExplorerUrl;
    const request = this.httpService.sendPost(url, data);
    request.subscribe(
      response => {
        $("div#loader").hide();
        $("img#hourglass").hide();
        if (response['status'] === 'success') {
          this.notificationService.success(
            'Operation with dataset ID ' + datasetID + ' created!'
          );

          // Notify DatatableComponent to re-fetch operations and update view
          this.operationsService.dataChanged.next();
          location.reload();
        } else {
          throw Error('Operation failed: ' + response['details']);
        }
      },

      error => {
        throw error;
      }
    );
  }

  /**
   * Returns an Observable that returns a list of Operations belonging to a dataset.
   *
   * @param datasetID Return operations belonging to this dataset
   * @param scrolling If false, returns most recent operations.
   * Otherwise, returns operations uploaded before lastConversationID and current operations.
   * @param numResponses Number of responses to return
   */
  listOperations(
    datasetID: number,
    scrolling: boolean
  ): Observable<Operation[]> {
    const url = this.httpService.getUrl() + this.listOperationsUrl + datasetID;

    return this.httpService.sendGet(url).pipe(
      map(response => {
        const operationList = response['task_list'];
        if (!scrolling) {
          this.datasetOperations = [];
          this.datasetOperations = this.operationsService.getOperationsList(
            operationList
          );
        } else {
          this.datasetOperations.push(
            ...this.operationsService.getOperationsList(operationList)
          );
        }

        return this.datasetOperations.slice();
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Returns an Observable that deletes a dataset.
   *
   * @param datasetID Dataset to delete
   */
  removeDatasets(datasetID: number) {
    const data = {
      dataset_id: datasetID
    };
    const url = this.httpService.getUrl() + this.removeDatasetUrl;

    return this.httpService.sendDelete(url, data).pipe(
      map(response => {
        if (response['status'] !== 'error') {
          this.notificationService.success(
            'Dataset ' + datasetID + ' removed!'
          );
          location.reload();
        } else {
          throw Error(response['details']);
        }
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Returns a map of Dataset keys to titles.
   */
  getTitles() {
    return this.datasetTitles;
  }

  /**
   * Returns a list of Dataset keys.
   */
  getKeys() {
    return Object.keys(this.datasetTitles);
  }

  
}
