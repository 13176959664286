import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatchartService {

  constructor() { 
    this.wcTopicChange.subscribe((value) => {
      this.wcTopicToView = value
    });
    this.wcNeedsChange.subscribe((value) => {
      this.wcNeedsToView = value
    });
  }

  public dataToView: string; // plotly

  public wcTopicToView: string;
  wcTopicChange = new BehaviorSubject('');
  public topics$ = this.wcTopicChange.asObservable();

  changeTopicWCToView(barName) {
    this.wcTopicChange.next(barName);
  }

  public wcNeedsToView: string;
  wcNeedsChange = new BehaviorSubject('');
  public needs$ = this.wcNeedsChange.asObservable();

  changeNeedsWCToView(barName) {
    this.wcNeedsChange.next(barName);
  }

  public genderLabels = ['Male', 'Female', 'Other'];

  public ageLabels = ['< 20', '21-35', '36-45', '46-60', '> 60'];

  public topicLabels = [
    'Physical', 
    'Emotional', 
    '',
    'Diagnosis', 
    'Laboratory', 
    'Imaging', 
    'Prescription', 
    'Alternative', 
    '',
    'Device', 
    'Therapy', 
    'Counselling', 
    '',
    'Diet', 
    'Exercise', 
    'Substance', 
    '',
    'Housing', 
    'Work', 
    'Soc. Service', 
    'Relationship', 
    'Cultural', 
    '',
    'Outpatient', 
    'Hospital', 
    'Technical / IT'
  ]

  public symptomsTopics = [
    'Physical', 
    'Emotional'
  ]
  public diagnosticsTopics = [
    'Diagnosis', 
    'Laboratory', 
    'Imaging', 
    'Prescription', 
    'Alternative'
  ]
  public treatmentTopics = [
    'Device', 
    'Therapy', 
    'Counselling'
  ]
  public lifestyleTopics = [
    'Diet', 
    'Exercise', 
    'Substance'
  ]
  public socialTopics = [
    'Housing', 
    'Work', 
    'Soc. Service', 
    'Relationship', 
    'Cultural'
  ]
  public logisticTopics = [
    'Outpatient', 
    'Hospital', 
    'Technical / IT'
  ]

  public parentTopicLabels = [
    'Symptoms',
    'Diagnostics',
    'Treatment',
    'Lifestyle',
    'Social',
    'Logistical'
  ]

  public needLabels = ['Physical', 'Emotional', 'Social', 'Health Info'];

  public genderTitle = {
    text: 'Gender',
    font: {
      family: 'Open Sans, sans-serif',
      size: 24,
    },
    xref: 'paper',
    x: 5,
  }

  public ageTitle = {
    text: 'Age',
    font: {
      family: 'Open Sans, sans-serif',
      size: 24,
    },
    xref: 'paper',
    x: 5,
  }

  public topicsTitle = {
    text: 'Conversational Topics',
    font: {
      family: 'Open Sans, sans-serif',
      size: 24,
    },
    xref: 'paper',
    x: 5,
  }

  public needTitle = {
    text: 'Patient Need Types',
    font: {
      family: 'Open Sans, sans-serif',
      size: 24,
    },
    xref: 'paper',
    x: 5,
  }
}
