import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';

/**
 * Service to return necessary information for displaying pre-login pages.
 */
@Injectable()
export class PreLoginService {
  /** Map of pre-login form fields to icons and form inputs */
  formFields = {
    email: {
      icon: 'fa-at',
      inputs: [
        {
          id: 'email',
          label: 'Email',
          type: 'email',
          validators: [Validators.required, Validators.email, Validators.pattern("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")]
        }
      ]
    },
    name: {
      icon: 'fa-user-circle',
      inputs: [
        {
          id: 'firstname',
          label: 'First name',
          type: 'text',
          validators: [Validators.required]
        },
        {
          id: 'lastname',
          label: 'Last name',
          type: 'text',
          validators: [Validators.required]
        }
      ]
    },
    username: {
      icon: 'fa-user',
      inputs: [
        {
          id: 'username',
          label: 'Username',
          type: 'text',
          validators: [Validators.required]
        }
      ]
    },
    password: {
      icon: 'fa-lock',
      inputs: [
        {
          id: 'password',
          label: 'Password',
          type: 'password',
          validators: [Validators.required]
        }
      ],
      isPassword: true
    },
    newPassword: {
      icon: 'fa-lock',
      inputs: [
        {
          id: 'newPassword',
          label: 'New Password',
          type: 'password',
          validators: [Validators.required]
        }
      ],
      isPassword: true
    }
  };

  /** Map of pre-login routes to content to display */
  routes = {
    '/STAT/login': {
      title: 'Login',
      fields: [this.formFields.username, this.formFields.password],
      links: ['/STAT/signup', '/STAT/password-reset']
    },
    '/STAT/signup': {
      title: 'Create an Account',
      fields: [
        this.formFields.name,
        this.formFields.username,
        this.formFields.email,
        this.formFields.password
      ],
      links: ['/STAT/login']
    },
    '/STAT/password-reset': {
      title: 'Reset Password',
      fields: [this.formFields.email],
      links: ['/STAT/signup', '/STAT/login']
    },
    '/STAT/password-change': {
      title: 'Change Password',
      fields: [this.formFields.newPassword],
      links: ['/STAT/signup', '/STAT/login']
    }
  };

  constructor() {}

  /**
   * Returns a map of pre-login form fields to icons and form inputs
   */
  getFormFields(): any {
    return this.formFields;
  }

  /**
   * Returns a map of pre-login routes to content to display.
   */
  getRoutes(): any {
    return this.routes;
  }
}
