import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as d3 from 'd3';

/**
 * Component to display a piechart.
 * Upon slice hover, hover effects and a tooltip containing slice data are displayed.
 */
@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss']
})
export class PiechartComponent implements OnInit {
  /** SVG width */
  @Input()
  width;
  /** SVG height */
  @Input()
  height;

  /** Data [ {name, score, percent}, ... ] to display for piechart */
  @Input()
  data;
  /** Sum of each data entry's score */
  @Input()
  total;
  /** Piechart inner radius */
  @Input()
  innerRadius;
  /** Piechart radius */
  radius;
  /** Arc corner radius */
  cornerRadius = 4;
  /** Padding between adjacent slices is calculated by padRadius * padAngle */
  padRadius = 100;
  /** Padding between adjacent slices is calculated by padRadius * padAngle */
  padAngle = 0.02;
  /** Margin between piechart and SVG */
  margin = 50;

  /** Piechart color function */
  @Input()
  color;
  /** Domain of piechart color function comprise of data names */
  colorDomain = 'name';

  /** Function pie(data) generates an array of slice data [{data, value, startAngle, endAngle, ...}, ...] */
  pie;
  /** Setting to 'd' attribute of <path> generates path data for unhovered slices */
  arc;
  /** Setting to 'd' attribute of <path> generates path data for hovered slices */
  arcHover;

  /** References SVG in the template */
  svg;
  /** References tooltip DOM element */
  tooltip;

  /** Notify parent component after piechart has been created */
  @Output()
  pieCreated = new EventEmitter();

  constructor() {}

  /**
   * Called after initializing properties.
   */
  ngOnInit() {
    this.radius = (Math.min(this.width, this.height) - 2 * this.margin) / 2;
    this.initSvg();
    this.drawPie();
  }

  /**
   * Initializes piechart hover tooltip.
   */
  initTooltip() {
    this.tooltip = d3
      .select('#piechart')
      .append('div')
      .attr('class', 'tooltip');

    // Placeholder divs to display name and score of hovered slice
    for (const key of Object.keys(this.data[0])) {
      this.tooltip.append('div').attr('class', key);
    }

    // Placeholder div to display percent of hovered slice
    this.tooltip.append('div').attr('class', 'percent');
  }

  /**
   * Defines piechart properties.
   */
  initPie() {
    // Define how to extract value from each entry in this.data
    //
    // this.pie(this.data) generates an array of slice data, where array[i] is
    // {data: this.data[i], value: data[score], startAngle, endAngle, ...}
    this.pie = d3.pie().value((d: any) => d.score);

    // Define properties for an unhovered slice.
    //
    // Setting to 'd' attribute of <path> will
    // generate path data for unhovered slices.
    this.arc = d3
      .arc()
      .outerRadius(this.radius)
      .innerRadius(this.innerRadius)
      .padAngle(this.padAngle)
      .padRadius(this.padRadius)
      .cornerRadius(this.cornerRadius);

    // Define properties for a hovered slice: outer radius should increase on hover.
    //
    // Setting to 'd' attribute of <path> will
    // generate path data for hovered slices.
    this.arcHover = d3
      .arc()
      .outerRadius(this.radius + 10)
      .innerRadius(this.innerRadius)
      .padAngle(this.padAngle)
      .padRadius(this.padRadius)
      .cornerRadius(this.cornerRadius);

    this.initTooltip();
  }

  /**
   * Defines SVG properties.
   */
  initSvg() {
    // Append <svg> to #piechart with specified width and height.
    // Append <g> to <svg> and center it in the containing <svg>.
    this.svg = d3
      .select('#piechart')
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr(
        'transform',
        'translate(' + [this.width / 2, this.height / 2] + ')'
      );

    this.initPie();
  }

  /**
   * Handles pie slice hover. Slice is referenced by n[i].
   *
   * @param d Slice data {data, value, endAngle, index, ...}
   * @param i Index of <path> (slice) in n
   * @param n <path> array
   */
  handleMouseover(d, i, n) {
    // Select hovered <path> (slice),
    // generate path data (define 'd' attribute using this.arcHover function),
    // and define transition properties
    d3.select(n[i])
      .transition()
      .duration(500)
      .attr('d', this.arcHover)
      .attr('opacity', 0.5);

    // Define tooltip text for slice's name and score
    for (const key of Object.keys(d.data)) {
      this.tooltip.select('.' + key).html(d.data[key]);
    }

    // Define tooltip text for slice's percentage (2 decimals)
    this.tooltip
      .select('.percent')
      .html(((d.data['score'] / this.total) * 100).toFixed(2) + '%');

    // Display tooltip and define transition properties
    this.tooltip
      .transition()
      .duration(500)
      .style('opacity', 0.7);
  }

  /**
   * Handles pie slice mousemove.
   */
  handleMousemove() {
    // As mouse moves, tooltip position stays above mouse
    this.tooltip
      .style('top', d3.event.layerY - 90 + 'px')
      .style('left', d3.event.layerX - 25 + 'px');
  }

  /**
   * Handles pie slice mouseout. Slice is referenced by n[i].
   * @param d slice data {data, value, endAngle, index, ...}
   * @param i index of <path> (slice) in n
   * @param n <path> array
   */
  handleMouseout(d, i, n) {
    // Select unhovered <path> (slice),
    // generate path data (define 'd' attribute using this.arc function),
    // and define transition properties
    d3.select(n[i])
      .transition()
      .duration(500)
      .attr('d', this.arc)
      .attr('opacity', '1');

    this.tooltip.style('opacity', 0);
  }

  /**
   * Draws piechart.
   */
  drawPie() {
    // Select all '.arc' inside the svg. Currently, they don't exist.
    //
    // data() associates pie data with '.arc' elements that will be created.
    // enter() creates placeholder nodes for each entry in this.data.
    // append() replaces placeholders with <g class="arc">
    const g = this.svg
      .selectAll('.arc')
      .data(this.pie(this.data))
      .enter()
      .append('g')
      .attr('class', 'arc');

    // Append <path> to each 'g.arc'. Each <path> represents a pie slice.
    //
    // For each <path>:
    // - generate path data (define 'd' attribute using this.arc function)
    // - generate color using style('fill', (d) => {...}), where
    //   d is slice data {data, value, startAngle, ...}
    // - generate hover handlers: (d, i, n) is (slice data, index of <path>, <path> array)
    const path = g
      .append('path')
      .attr('d', this.arc)
      .style('fill', (d: any) => this.color(d.data[this.colorDomain]))
      .attr('cursor', 'pointer')
      .on('mouseover', (d, i, n) => {
        this.handleMouseover(d, i, n);
      })
      .on('mousemove', d => this.handleMousemove())
      .on('mouseout', (d, i, n) => {
        this.handleMouseout(d, i, n);
      });

    this.pieCreated.emit({
      radius: this.radius,
      g: g,
      svg: this.svg
    });
  }
}
