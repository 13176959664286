import { Component, OnInit, Input } from '@angular/core';

/**
 * Component to display sentiment analysis for an operation.
 */
@Component({
  selector: 'app-sentiments',
  templateUrl: './sentiments.component.html',
  styleUrls: ['./sentiments.component.scss']
})
export class SentimentsComponent implements OnInit {
  /** Unsorted data is provided by the parent component */
  @Input()
  data;
  /** Sorted tokens data to display in table */
  dataSorted = [];
  /** Sorted tokens data keys */
  dataSortedKeys;

  /** Map of data keys to titles */
  titles = {
    anger_tokens: 'Anger',
    anticipation_tokens: 'Anticipation',
    disgust_tokens: 'Disgust',
    fear_tokens: 'Fear',
    joy_tokens: 'Joy',
    sadness_tokens: 'Sadness',
    suprise_tokens: 'Surprise',
    trust_tokens: 'Trust',
    positive_score: 'Positive',
    negative_score: 'Negative'
  };
  /** Data keys */
  keys = Object.keys(this.titles);

  /** Sorted positive/negative data to display in piechart */
  posNeg = [];
  /** Sorted sentiment scores data to display in piechart */
  scores = [];
  /** Total positive/negative score */
  posNegTotal = 0;
  /** Total sentiments score */
  scoresTotal = 0;

  constructor() {}

  /**
   * Called after initializing properties.
   */
  ngOnInit() {
    this.getSortedData();
    this.getScores();
  }

  /**
   * Sort data so that, for each key where this.data[key] contains {token1: freq1, token2: freq1, ...},
   * this.dataSorted[i][key] contains the value at this.data[key][i], and
   * this.dataSorted[i][key_freq] contains its frequency
   */
  getSortedData() {
    let i = 0;
    let rowEmpty = false; // True if dataSorted[i] is empty at the end of the loop

    while (!rowEmpty) {
      rowEmpty = true; // Will set to false if a key contains an i-th value

      const obj = {};

      for (const key of this.keys) {
        // E.g., this.data = { anger_tokens: {shit: 2, angry: 1, ...}, ...}
        // col = {shit: 2, angry: 1, ...}
        // colKeys = [shit, angry, ...]
        const col = this.data[key];
        const colKeys = Object.keys(col);

        // If col contains an i-th value, set obj[key] and obj[key_freq]
        // Otherwise, set both to empty strings
        if (colKeys[i]) {
          obj[key] = colKeys[i];
          obj[key + '_freq'] = col[colKeys[i]];
          rowEmpty = false;
        } else {
          obj[key] = '';
          obj[key + '_freq'] = '';
        }
      }

      if (!rowEmpty) {
        this.dataSorted[i] = obj;
        i++;
      }
    }

    this.dataSortedKeys = this.dataSorted.length
      ? Object.keys(this.dataSorted[0])
      : [];
  }

  /**
   * Sort data so that, for each key where this.data[key] contains a score,
   * this.posNeg contains { name, score } if key = positive/negative_sentiment_score, and
   * this.scores contains { name, score } otherwise
   *
   * Calculate this.posNegTotal and this.scoresTotal
   */
  getScores() {
    for (const key of Object.keys(this.data)) {
      if (key.includes('_sentiment_score')) {
        const obj = {};
        obj['name'] = key.replace('_sentiment_score', '');
        obj['score'] = this.data[key];
        this.posNeg.push(obj);
        this.posNegTotal += this.data[key];
      } else if (
        key.includes('_score') &&
        !key.includes('positive') &&
        !key.includes('negative')
      ) {
        const obj = {};
        obj['name'] = key.replace('_score', '');
        obj['score'] = this.data[key];
        this.scores.push(obj);

        this.scoresTotal += this.data[key];
      }
    }
  }
}
