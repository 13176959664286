import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

/**
 * Component to display conversations dropdown filter.
 */
@Component({
  selector: 'app-conversation-filter',
  templateUrl: './conversation-filter.component.html',
  styleUrls: ['./conversation-filter.component.scss']
})
export class ConversationFilterComponent {
  /** References the form in the template */
  @ViewChild('filter')
  filterForm: NgForm;

  /** Notifies parent component after submitting filter */
  @Output()
  filterInput = new EventEmitter<{}>();

  /** Date filters */
  dateCols = {
    timestamp_start: { title: 'Start Date', min: null, max: null },
    timestamp_end: { title: 'End Date', min: null, max: null },
    timestamp_upload: { title: 'Upload Date', min: null, max: null }
  };

  /** Number filters */
  filterCols = {
    num_msgs: { title: 'Message #', min: null, max: null },
    num_participants: { title: 'Participant #', min: null, max: null }
  };

  constructor() {}

  /**
   * Handles date-picker changes.
   *
   * @param event An Object { minMax: string, dateFormatted: string }, where minMax is either 'min' or max'
   * @param key A key in dateCols
   */
  onDateChanged(event: any, key: any) {
    this.dateCols[key][event.minMax] = event.dateFormatted;
  }

  /**
   * Handles form submission, and passes submitted filter to parent component.
   */
  onSubmit() {
    this.filterInput.emit({
      filterCols: this.filterCols,
      dateCols: this.dateCols
    });
  }

  /**
   * Handles dropdown form click, and stops event propagation.
   *
   * @param event Click event
   */
  onFormClick(event) {
    event.stopPropagation(); // Otherwise dropdown will close
  }

  /**
   * Returns the list of keys in dateCols.
   */
  getDateColsKeys() {
    return Object.keys(this.dateCols);
  }

  /**
   * Returns the list of keys in filterCols.
   */
  getFilterColsKeys() {
    return Object.keys(this.filterCols);
  }
}
