import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  ComponentRef
} from '@angular/core';
import { Conversation } from '../../shared/models/conversation.model';
import { Dataset } from '../../shared/models/dataset.model';
import { DatasetsService } from '../../shared/services/datasets.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';

/**
 * Modal to add/remove conversations (provided by the parent component) to/from a dataset.
 *
 * If the parent component provides a dataset ID, the modal removes provided conversations from this dataset.
 * Otherwise, the modal adds provided conversations to an existing or new dataset.
 *
 */
@Component({
  selector: 'app-conversations-dataset-edit',
  templateUrl: './conversations-dataset-edit.component.html',
  styleUrls: ['./conversations-dataset-edit.component.scss']
})
export class ConversationsDatasetEditComponent
  implements IModalDialog, OnDestroy {
  /** References the form in the template */
  @ViewChild('form')
  form: NgForm;

  /** Conversations to add or remove is provided by the parent component */
  conversations: Conversation[];

  /**
   * ID of this dataset is provided by the parent component.
   *
   * If datasetID exists, remove conversations from this dataset.
   * Otherwise, add conversations to a dataset.
   */
  datasetID: number;
  /** Datasets fetched from the database */
  datasets: Dataset[];
  /** Dataset in which to add conversations */
  dataset: Dataset;
  /** If true, display form fields to create a new dataset. */
  newDataset = false;

  /** Subscription to datasetsService.dataScrolled for infinite scrolling datasets */
  dataScrolledSubscription: Subscription;

  constructor(
    private datasetsService: DatasetsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /**
   * Called after initialization.
   *
   * Receives data from parent component and initializes variables.
   *
   * @param reference
   * @param options Contains modal options (including data) from parent component.
   */
  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<string>>
  ) {
    this.conversations = options.data['conversations'];
    this.datasetID = options.data['datasetID'];

    // If datasetID does not exist, fetch datasets from the database so that
    // user can select a dataset in which to add conversations.
    if (!this.datasetID) {
      this.initDatasets();
    }
  }

  /**
   * Called when modal is destroyed.
   */
  ngOnDestroy() {
    if (!this.datasetID) {
      this.dataScrolledSubscription.unsubscribe();
    }
  }

  /**
   * Initializes datasets.
   */
  initDatasets() {
    this.getDatasets();

    // dataScrolled is called by this.onScroll()
    // Need to GET more datasets from the database for infinite scrolling
    this.dataScrolledSubscription = this.datasetsService.dataScrolled.subscribe(
      () => this.getDatasets(true)
    );
  }

  /**
   * Sets this.datasets to datasets fetched from the database, and update view.
   *
   * @param scrolling Set to true if loading more datasets for infinite scrolling.
   */
  getDatasets(scrolling?: boolean) {
    this.datasetsService.getData(scrolling).subscribe((datasets: Dataset[]) => {
      this.datasets = datasets;
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * Handles scrolling to bottom of multiple select input for datasets.
   */
  onScroll() {
    // Notify this component to GET more datasets for infinite scrolling
    this.datasetsService.dataScrolled.next();
  }

  /**
   * Handles dataset select event.
   *
   * @param value 'new' if 'New Dataset' is selected, otherwise index of selected dataset in datasets
   */
  onSelectDataset(value: string) {
    if (value === 'new') {
      this.newDataset = true;
    } else {
      this.newDataset = false;
      this.dataset = this.datasets[value];
    }
  }

  /**
   * Handles form submission.
   */
  onConfirm() {
    if (!this.datasetID && this.form.value.datasetName) {
      this.addToNewDataset();
    } else {
      this.modifyDataset();
    }
  }

  /**
   * Adds conversations to a new dataset.
   */
  addToNewDataset() {
    // - Commented for now, removing conversations page -
    // Create a new dataset, and use the returned datasetID to add conversations to the new dataset.
    // this.datasetsService
    //   .createDataset(
    //     this.form.value.datasetName,	
    //     this.form.value.datasetDescription
    //   )
    //   .subscribe((datasetID: number) => this.modifyDataset(datasetID));
  }

  /**
   * Adds/removes conversations to/from a dataset.
   *
   * @param newDatasetID ID of the new dataset
   */
  modifyDataset(newDatasetID?: number) {
    // If this.datasetID exists, remove conversations from this.datasetID.
    // Otherwise, add conversations to the new dataset (newDatasetID) or the selected dataset (this.dataset)
    const id = this.datasetID || newDatasetID || this.dataset.getID();

    this.datasetsService
      .modifyConversations(
        id,
        this.conversations.map(convo => convo.getID()),
        this.datasetID == null
      )
      .subscribe(() => this.done());
  }

  /**
   * Call after database has been updated with modified dataset.
   */
  done() {
    // Reset form
    this.newDataset = false;
    this.form.reset();
    this.form.form.patchValue({
      datasetSelect: '' // Select default option ('Choose Dataset')
    });
  }
}
