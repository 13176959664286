import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { IModalDialog } from 'ngx-modal-dialog';
import { NgForm } from '@angular/forms';
import { DatasetsService } from '../../shared/services/datasets.service';
import { Subscription } from 'rxjs';
import { Dataset } from '../../shared/models/dataset.model';

/**
 * Modal to select a dataset and create an operation.
 */
@Component({
  selector: 'app-operation-new',
  templateUrl: './operation-new.component.html',
  styleUrls: ['./operation-new.component.scss']
})
export class OperationNewComponent implements IModalDialog, OnDestroy {
  /** References the form in the template */
  @ViewChild('form')
  form: NgForm;

  /** Datasets fetched from the database */
  datasets: Dataset[];

  /** Subscription to datasetsService.dataScrolled for infinite scrolling datasets */
  dataScrolledSubscription: Subscription;

  constructor(
    private datasetsService: DatasetsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /**
   * Called after initialization.
   *
   * Receives data from parent component and initializes variables.
   *
   * @param reference
   * @param options Contains modal options (including data) from parent component.
   */
  dialogInit() {
    this.getDatasets();

    // dataChanged is called by this.onScroll()
    // Need to GET more datasets from DB for infinite scrolling
    this.dataScrolledSubscription = this.datasetsService.dataScrolled.subscribe(
      () => this.getDatasets(true)
    );
  }

  /**
   * Called when modal is destroyed.
   */
  ngOnDestroy() {
    this.dataScrolledSubscription.unsubscribe();
  }

  /**
   * Sets this.datasets to datasets fetched from the database, and update view.
   *
   * @param scrolling Set to true if loading more datasets for infinite scrolling.
   */
  getDatasets(scrolling?: boolean) {
    this.datasetsService.getData(scrolling).subscribe((datasets: Dataset[]) => {
      this.datasets = datasets;
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * Handles scrolling to bottom of multiple select input for conversations.
   */
  onScroll() {
    // Notify this component to GET more datasets for infinite scrolling
    this.datasetsService.dataScrolled.next();
  }

  /**
   * Handles form submission.
   */
  onConfirm() {
    const datasetID = this.datasets[this.form.value.datasetSelect].getID();
    this.datasetsService.createOperation(
      datasetID
    );
  }
}
