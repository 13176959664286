import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { FilterService } from '../shared/services/filter.service';
import { Operation } from '../shared/models/operation.model';
import { OperationsService } from '../shared/services/operations.service';
import { ModalDialogService } from 'ngx-modal-dialog';
import { OperationComponent } from './operation/operation.component';
import { OperationNewComponent } from './operation-new/operation-new.component';

/**
 * Component to display a filtered list of either all operations or dataset operations.
 *
 * If the parent component provides a dataset ID, the component displays the dataset operations.
 * Otherwise, the component displays all operations.
 */
@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent implements OnInit {
  /** If title is not provided by the parent component, set to 'Operations' */
  @Input()
  title: string;

  /**
   * ID of this dataset is provided by the parent component.
   *
   * If datasetID exists, display dataset operations.
   * Otherwise, display all operations
   */
  @Input()
  datasetID: number;

  /** Operations fetched from the database */
  operations: Operation[];
  /** Filtered operations */
  operationsFiltered: Operation[];

  /** Filter operations based on this value */
  filter = {
    searchString: null,
    dateCols: null,
    statusCol: null
  };

  constructor(
    private operationsService: OperationsService,
    private filterService: FilterService,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef
  ) {}

  /**
   * Called after initializing properties.
   */
  ngOnInit() {
    this.title = this.title || 'Operations';
  }

  /**
   * Filters operations.
   */
  filterOperations() {
    this.operationsFiltered = <Operation[]>(
      this.filterService.transform(this.operations, this.filter, 'operations')
    );

    // Notify DatatableComponent to display filtered operations
    this.operationsService.dataFiltered.next(this.operationsFiltered);
  }

  /**
   * Handles filter changes, and filters operations based on the changes.
   *
   * @param filterFields Changed filter fields
   */
  filterChanged(filter) {
    this.filter = { ...this.filter };
    for (const key of Object.keys(filter)) {
      this.filter[key] = filter[key];
    }
    this.filterOperations();
  }

  /**
   * Handles filter inputs, and filters operations based on the changes.
   *
   * @param filterFields Changed filter fields
   */
  onFilterInput(filter: any) {
    this.filterChanged(filter);
  }

  /**
   * Handles search inputs, and filters operations according to the search string.
   *
   * @param searchString Current search string
   */
  onSearchInput(searchString: string) {
    this.filterChanged({ searchString: searchString });
  }

  /**
   * Handles changes in operations, and filters updated operations.
   *
   * @param operations Updated list of operations
   */
  onOperationsChanged(operations: Operation[]) {
    this.operations = operations;
    this.filterOperations();
  }

  /**
   * Handles 'Refresh Operations' button click, and obtains status updates for
   * displayed operations that are in progress.
   */
  onRefreshOperationsClick() {
    this.operations.forEach((operation: Operation) => {
      const currentStatus = operation.getStatus();

      // Check for updates on in-progress operations
      if (this.operationsService.inProgress(currentStatus)) {
        this.operationsService
          .updateStatus(operation.getID())
          .subscribe((status: string) => {
            if (status !== currentStatus) {
              // Notify DatatableComponent to re-fetch operations and update view
              this.operationsService.dataChanged.next();
            }
          });
      }
    });
  }

  /**
   * Handles operation row click, and triggers modal to display the operation.
   *
   * @param operation Operation clicked
   */
  onOperationClick(operation: Operation) {
    this.modalService.openDialog(this.viewRef, {
      title: 'Operation ' + operation.getID(),
      childComponent: OperationComponent,
      data: {
        operation: operation,
        operationTitles: this.operationsService.getTitles(),
        operationKeys: this.operationsService.getKeys()
      }
    });
  }

  /**
   * Handles 'New Operation' button click, and triggers modal to add a new operation.
   */
  onNewOperationClick() {
    this.modalService.openDialog(this.viewRef, {
      title: 'New Operation',
      childComponent: OperationNewComponent
    });
  }
}
