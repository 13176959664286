import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Operation } from '../models/operation.model';
import { Subject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';

/**
 * Service to interact with the server and perform operations to:
 * get operations;
 * update an operation status;
 * cancel an operation;
 * get outputs for an operation.
 */
@Injectable()
export class OperationsService {
  /** Operations fetched from the database */
  operations: Operation[] = [];

  /** Parent route of all operations endpoints */
  operationsUrl = '/nlp';
  /** URL to GET a list of operations */
  listOpsUserUrl = this.operationsUrl + '/list_tasks_for_user';
  /** URL to GET an updated status */
  updateStatusUrl = this.operationsUrl + '/update_status/';
  /** URL to GET an updated status */
  cancelUrl = this.operationsUrl + '/cancel/';
  /** URL to GET output URLS for a task */
  outputUrl = this.operationsUrl + '/output_for_task/';

  /** Call after operations in the database have been modified */
  dataChanged = new Subject();
  /** Call after scrolling to the bottom of an operations list */
  dataScrolled = new Subject();
  /** Call after operations have been filtered, and pass the filtered operations. */
  dataFiltered = new Subject<Operation[]>();

  /** Map of Operation keys to titles */
  operationTitles = {
    task_id: 'ID',
    task_status: 'Status',
    created_timestamp: 'Date Created',
    dataset_id: 'Dataset ID'
  };

  constructor(
    private httpService: HttpService,
    private notificationService: NotificationService
  ) {}

  /**
   * Returns an Observable that returns a list of all Operations.
   */
  getData(): Observable<Operation[]> {
    const url = this.httpService.getUrl() + this.listOpsUserUrl;
    return this.httpService.sendGet(url).pipe(
      map(response => {
        this.operations = this.getOperationsList(response['task_list']);
        return this.operations.slice();
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Returns an Operation.
   *
   * @param operation Operation from GET result
   */
  getOperationModel(operation: any): Operation {
    return new Operation(
      this.getFormattedDate(operation.created_timestamp),
      operation.dataset_id,
      operation.task_id,
      operation.task_status === 'processing'
        ? operation['progress']
        : operation.task_status
    );
  }

  /**
   * Returns a formatted date string.
   *
   * @param date Date string from GET result
   */
  getFormattedDate(date: string): string {
    return new Date(date).toLocaleDateString();
  }

  /**
   * Returns a list of Operations.
   *
   * @param operations Operation list from GET result
   */
  getOperationsList(operations: any): Operation[] {
    if (!operations) {
      return [];
    }

    const list: Operation[] = [];
    for (const operation of operations) {
      list.push(this.getOperationModel(operation));
    }
    return list;
  }

  /**
   * Returns an Observable that returns the current status for an operation.
   *
   * @param operationID Operation whose current status to return
   */
  updateStatus(operationID: number): Observable<string> {
    const url = this.httpService.getUrl() + this.updateStatusUrl + operationID;

    return this.httpService.sendGet(url).pipe(
      map(response => {
        if (response['status'] !== 'error') {
          const status =
            response['status'] === 'processing'
              ? 'processing: ' + response['progress']
              : response['status'];

          this.notificationService.success(
            'Status for Operation ' + operationID + ': ' + status
          );
          return status;
        }

        throw Error(response['error']);
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Returns an Observable that cancels an operation and
   * returns the resulting status.
   *
   * @param operationID Operation to cancel
   */
  cancelOperation(operationID: number): Observable<string> {
    const url = this.httpService.getUrl() + this.cancelUrl + operationID;

    return this.httpService.sendGet(url).pipe(
      map(response => response['status']),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Returns an Observable that returns the output URLs for an operation.
   *
   * @param operationID Operation whose output URLs to return
   */
  getOutput(operationID: number): Observable<Object[]> {
    const url = this.httpService.getUrl() + this.outputUrl + operationID;

    return this.httpService.sendGet(url).pipe(
      map(response => response['outputs']),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Returns the content at the given URL.
   *
   * @param url URL whose content to return
   */
  getOutputFile(url: string): Observable<Object> {
    return this.httpService.sendGet(url).pipe(
      map(response => response),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Return true if the given status indicates that a task is in progress.
   *
   * @param status Status to check
   */
  inProgress(status: string): boolean {
    return (
      status !== 'complete' && status !== 'cancelled' && status !== 'failed'
    );
  }

  /**
   * Returns a map of Operation keys to titles.
   */
  getTitles(): Object {
    return this.operationTitles;
  }

  /**
   * Returns a list of Operation keys.
   */
  getKeys(): string[] {
    return Object.keys(this.operationTitles);
  }
}
