import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';

import { AnalysisComponent } from './analysis.component';
import { FrequenciesModule } from './frequencies/frequencies.module';
import { MulticonvisModule } from './multiconvis/multiconvis.module';
import { SentimentsModule } from './sentiments/sentiments.module';

/**
 * Module to display the analysis overview for an operation, and either:
 * frequencies analysis, sentiments analysis, or multiConvis analysis.
 */
@NgModule({
  imports: [
    SharedModule,
    FrequenciesModule,
    MulticonvisModule,
    SentimentsModule
  ],
  declarations: [AnalysisComponent],
  exports: [AnalysisComponent]
})
export class AnalysisModule {}
