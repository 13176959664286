import { Component, Input, ViewChild, ComponentRef } from '@angular/core';
import { Conversation } from '../../shared/models/conversation.model';
import { ConversationsService } from '../../shared/services/conversations.service';
import { NgForm } from '@angular/forms';
import { DatasetsService } from '../../shared/services/datasets.service';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';

/**
 * Modal to select conversations and add them to the dataset (provided by the parent component).
 */
@Component({
  selector: 'app-conversations-dataset-new',
  templateUrl: './conversations-dataset-new.component.html',
  styleUrls: ['./conversations-dataset-new.component.scss']
})
export class ConversationsDatasetNewComponent implements IModalDialog {
  /** References the form in the template */
  @ViewChild('form')
  form: NgForm;

  /** ID of this dataset is provided by the parent component */
  datasetID;

  /** Conversations fetched from the database */
  conversations: Conversation[];
  /** Conversations selected for adding */
  conversationIDs = [];

  constructor(
    private conversationsService: ConversationsService,
    private datasetsService: DatasetsService
  ) {}

  /**
   * Called after initialization.
   *
   * Receives data from parent component and initializes variables.
   *
   * @param reference
   * @param options Contains modal options (including data) from parent component.
   */
  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<string>>
  ) {
    this.datasetID = options.data['datasetID'];

    this.getConversations();
  }

  /**
   * Sets this.conversations to conversations fetched from the database, and update view.
   *
   * @param scrolling Set to true if loading more conversations for infinite scrolling.
   */
  getConversations(scrolling?: boolean) {
    this.conversationsService
      .getData(scrolling)
      .subscribe(
        (conversations: Conversation[]) => (this.conversations = conversations)
      );
  }

  /**
   * Handles form submission, and adds selected conversations to this dataset.
   */
  onConfirm() {
    this.conversationIDs = [];

    // Get IDs of selected conversations
    // Value of each select option (conversation) is the conversation's index into this.conversations
    for (const i of this.form.value.convosSelect) {
      this.conversationIDs.push(this.conversations[i].getID());
    }

    this.datasetsService
      .modifyConversations(this.datasetID, this.conversationIDs, true)
      .subscribe(() => this.done());
  }

  /**
   * Call after database has been updated with modified dataset.
   */
  done() {
    this.form.reset();
    this.form.form.patchValue({
      convosSelect: '' // Default select option 'Choose Conversations'
    });
  }
}
