/**
 * Model to represent a dataset.
 */
export class Dataset {
  constructor(
    private dataset_id: number,
    private dataset_name: string,
    private dataset_description: string,
    private dataset_healthcentre: string,
    private created_timestamp: string,
    private male_count: number,
    private female_count: number,
    private x_count: number,
    private age_median: number,
    private age_range: number,
    private age_distribution: string,
    private patient_count: number,
    private convo_earliest_timestamp: string,
    private convo_latest_timestamp: string,
    private num_msgs: number,
    private file_name: string,
    private conversation_count: number,
    private num_conversations: number,
    private age_group: number, 
    private word_cloud_data: string,
    private provider_count: number,
    private operation_status: string,
    public task_id: number,
    private need_types: string,
    private med_topics: string,
    private wc_med_physical: string,
    private wc_med_emotional: string,
    private wc_med_diagnosis: string,
    private wc_med_laboratory: string,
    private wc_med_imaging: string,
    private wc_med_prescription: string,
    private wc_med_alternative: string,
    private wc_med_device: string,
    private wc_med_therapy: string,
    private wc_med_counselling: string,
    private wc_med_diet: string,
    private wc_med_exercise: string,
    private wc_med_substance: string,
    private wc_med_housing: string,
    private wc_med_work: string,
    private wc_med_social_service: string,
    private wc_med_ff: string,
    private wc_med_cultural: string,
    private wc_med_outpatient: string,
    private wc_med_hospitalization: string,
    private wc_med_technical: string,
    private wc_nt_physical: string,
    private wc_nt_emotional: string,
    private wc_nt_social: string,
    private wc_nt_health_info: string,
  ) {}

  /**
   * Returns the value of the given property.
   *
   * @param param Property value to return
   */
  get(param: string): any {
    return this[param];
  }

  /**
   * Returns Dataset ID.
   */
  getID(): number {
    return this.dataset_id;
  }

  /**
   * Returns Dataset name.
   */
  getName(): string {
    return this.dataset_name;
  }

  /**
   * Returns Dataset description.
   */
  getDescription(): string {
    return this.dataset_description;
  }

  getHealthCentre(): string {
    return this.dataset_healthcentre;
  }


  /**
   * Returns timestamp of Dataset creation.
   */
  getCreatedTimestamp(): string {
    return this.created_timestamp;
  }

  getMaleCount(): number {
    return this.male_count;
  }

  getFemaleCount(): number {
    return this.female_count;
  }

  getXCount(): number {
    return this.x_count;
  }

  getAgeRange(): number {
    return this.age_range;
  }
  getAgeMedian(): number {
    return this.age_median;
  }
  getAgeDist(): string {
    return this.age_distribution;
  }

  getPatientCount(): number {
    return this.patient_count;
  }

  getEarliestConvo(): string {
    return this.convo_earliest_timestamp;
  }
  getLatestConvo(): string {
    return this.convo_latest_timestamp;
  }

  getNumMsgs(): number {
    return this.num_msgs;
  }

  getFileName(): string {
    return this.file_name;
  }

  getConversationCount(): number {
    return this.conversation_count;
  }

  /**
   * Returns the number of Conversations in the Dataset.
   */
  getNumConversations(): number {
    return this.num_conversations;
  }

  getAgeGroup(): number {
    return this.age_group
  }

  getWordCloud(): string {
    return this.word_cloud_data
  }

  getProviderCount(): number {
    return this.provider_count
  }

  getOperationStatus(): string {
    return this.operation_status
  }

  getTaskId(): number {
    return this.task_id
  }
  getNeedTypes(): string {
    return this.need_types
  }

  getMedTopics(): string {
    return this.med_topics
  }
  getWCMedPhysical(): string {
    return this.wc_med_physical
  }
  getWCMedEmotional(): string {
    return this.wc_med_emotional
  }
  getWCMedDiagnosis(): string {
    return this.wc_med_diagnosis
  }
  getWCMedLaboratory(): string {
    return this.wc_med_laboratory
  }
  getWCMedImaging(): string {
    return this.wc_med_imaging
  }
  getWCMedPrescription(): string {
    return this.wc_med_prescription
  }
  getWCMedAlternative(): string {
    return this.wc_med_alternative
  }
  getWCMedDevice(): string {
    return this.wc_med_device
  }
  getWCMedTherapy(): string {
    return this.wc_med_therapy
  }
  getWCMedCounselling(): string {
    return this.wc_med_counselling
  }
  getWCMedDiet(): string {
    return this.wc_med_diet
  }
  getWCMedExercise(): string {
    return this.wc_med_exercise
  }
  getWCMedSubstance(): string {
    return this.wc_med_substance
  }
  getWCMedHousing(): string {
    return this.wc_med_housing
  }
  getWCMedWork(): string {
    return this.wc_med_work
  }
  getWCMedSocialService(): string {
    return this.wc_med_social_service
  }
  getWCMedFF(): string {
    return this.wc_med_ff
  }
  getWCMedCultural(): string {
    return this.wc_med_cultural
  }
  getWCMedOutpatient(): string {
    return this.wc_med_outpatient
  }
  getWCMedHospitalization(): string {
    return this.wc_med_hospitalization
  }
  getWCMedTechnical(): string {
    return this.wc_med_technical
  }

  getWCNTPhysical(): string {
    return this.wc_nt_physical
  }
  getWCNTEmotional(): string {
    return this.wc_nt_emotional
  }
  getWCNTSocial(): string {
    return this.wc_nt_social
  }
  getWCNTHealthInfo(): string {
    return this.wc_nt_health_info
  }
}
