import {
  Component,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { IModalDialog } from 'ngx-modal-dialog';
import { FormGroup } from '@angular/forms';
import { UserService } from '../../shared/services/user.service';
import { PreLoginService } from '../../shared/services/pre-login.service';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { DatasetsService } from '../../shared/services/datasets.service';
import { UploadEvent, FileSystemFileEntry } from 'ngx-file-drop';

/**
 * Modal to select conversations and add them to a new dataset.
 */
@Component({
  selector: 'app-user-topics',
  templateUrl: './user-topics.component.html',
  styleUrls: ['./user-topics.component.scss']
})
export class UserTopicsComponent implements IModalDialog {
  /** References the form in the template */
  @ViewChild('form')
  form: NgForm;

  /** Subscription to conversationsService.dataScrolled for infinite scrolling conversations */
  dataScrolledSubscription: Subscription;

  constructor(
    private datasetsService: DatasetsService,
    private userService: UserService,
    private preLoginService: PreLoginService
  ) {}
  
  /**
   * Called after initialization.
   *
   * Receives data from parent component and initializes variables.
   *
   * @param reference
   * @param options Contains modal options (including data) from parent component.
   */
  dialogInit() {
  }

  /**
   * Called when modal is destroyed.
   */
  ngOnDestroy() {
  }

  /**
   * Call after database has been updated with modified dataset.
   */
  done() {
    this.form.reset();
  }

  onAddUserTopic() {
      this.userService.addUserTopic(this.form.value.userTopic);
  }

}