import { Component } from '@angular/core';
import { IModalDialog } from 'ngx-modal-dialog';
import { UserService } from '../../shared/services/user.service';
import { FormGroup } from '@angular/forms';

/**
 * Modal to display password confirmation form and purge all data belonging to the user.
 */
@Component({
  selector: 'app-purge-data',
  templateUrl: './purge-data.component.html',
  styleUrls: ['./purge-data.component.scss']
})
export class PurgeDataComponent implements IModalDialog {
  constructor(private userService: UserService) {}

  /**
   * Called after initialization.
   */
  dialogInit() {}

  /**
   * Handles form submission.
   *
   * @param form References submitted form
   */
  onSubmit(form: FormGroup) {
    this.userService.purgeData(form.value.password);
  }
}
