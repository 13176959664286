import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { Conversation } from '../shared/models/conversation.model';
import { ConversationsService } from '../shared/services/conversations.service';
import { FilterService } from '../shared/services/filter.service';
import { ModalDialogService } from 'ngx-modal-dialog';
import { ConversationNewComponent } from './conversation-new/conversation-new.component';
import { ConversationsDatasetNewComponent } from './conversations-dataset-new/conversations-dataset-new.component';
import { ConversationComponent } from './conversation/conversation.component';
import { ConversationsDatasetEditComponent } from './conversations-dataset-edit/conversations-dataset-edit.component';
import { ConversationsRemoveComponent } from './conversations-remove/conversations-remove.component';

/**
 * Component to display a filtered list of either all conversations or dataset conversations.
 *
 * If the parent component provides a dataset ID, the component displays the dataset conversations.
 * Otherwise, the component displays all conversations.
 */
@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss']
})
export class ConversationsComponent implements OnInit {
  /** If title is not provided by the parent component, set to 'Conversations' */
  @Input()
  title: string;

  /**
   * ID of this dataset is provided by the parent component.
   *
   * If datasetID exists, display dataset conversations.
   * Otherwise, display all conversations
   */
  @Input()
  datasetID: number;

  /** Conversations fetched from the database */
  conversations: Conversation[];
  /** Filtered conversations */
  conversationsFiltered: Conversation[];
  /** Selected conversations */
  conversationsSelected = [];

  /** Filter conversations based on this value */
  filter = {
    searchString: null,
    dateCols: null,
    filterCols: null
  };

  constructor(
    private conversationsService: ConversationsService,
    private filterService: FilterService,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef
  ) {}

  /**
   * Called after initializing properties.
   */
  ngOnInit() {
    this.title = this.title || 'Conversations';
  }

  /**
   * Filters conversations.
   */
  filterConversations() {
    this.conversationsFiltered = <Conversation[]>(
      this.filterService.transform(
        this.conversations,
        this.filter,
        'conversations'
      )
    );

    // Notify DatatableComponent to display filtered conversations
    this.conversationsService.dataFiltered.next(this.conversationsFiltered);
  }

  /**
   * Handles filter changes, and filters conversations based on the changes.
   *
   * @param filterFields Changed filter fields
   */
  filterChanged(filterFields) {
    this.filter = { ...this.filter };
    for (const key of Object.keys(filterFields)) {
      this.filter[key] = filterFields[key];
    }
    this.filterConversations();
  }

  /**
   * Handles filter inputs, and filters conversations based on the changes.
   *
   * @param filterFields Changed filter fields
   */
  onFilterInput(filterFields: any) {
    this.filterChanged(filterFields);
  }

  /**
   * Handles search inputs, and filters conversations according to the search string.
   *
   * @param searchString Current search string
   */
  onSearchInput(searchString: string) {
    this.filterChanged({ searchString: searchString });
  }

  /**
   * Handles changes in conversations, and filters updated conversations.
   *
   * @param conversations Updated list of conversations
   */
  onConversationsChanged(conversations: Conversation[]) {
    this.conversations = conversations;
    this.filterConversations();
  }

  /**
   * Handles coonversations select and unselect.
   *
   * @param conversations Conversations selected
   */
  onConversationsSelected(conversations: Conversation[]) {
    this.conversationsSelected = conversations;
  }

  /**
   * Handles conversation row click, and triggers modal to display the conversation.
   *
   * @param conversation Conversation clicked
   */
  onConversationClick(conversation: Conversation) {
    this.modalService.openDialog(this.viewRef, {
      title: conversation.getID() + '. ' + conversation.getName(),
      childComponent: ConversationComponent,
      data: { conversation: conversation }
    });
  }

  /**
   * Handles 'Add Conversations' button click, and triggers modal to
   *             add conversations to this dataset, or
   *             upload new conversations to the system
   */
  onAddConversationsClick() {
    this.modalService.openDialog(this.viewRef, {
      title: this.datasetID ? 'Add Conversations' : 'Upload',
      childComponent: this.datasetID
        ? ConversationsDatasetNewComponent
        : ConversationNewComponent,
      data: { datasetID: this.datasetID }
    });
  }

  /**
   * Handles 'Add to Dataset' and 'Remove from Dataset' button clicks, and triggers modal to
   *             add conversations to an existing/new dataset, or
   *             remove conversations from this dataset.
   */
  onConversationsSelectClick() {
    this.modalService.openDialog(this.viewRef, {
      title: (this.datasetID ? 'Remove from ' : 'Add to ') + 'Dataset',
      childComponent: ConversationsDatasetEditComponent,
      data: {
        conversations: this.conversationsSelected,
        datasetID: this.datasetID
      }
    });
  }

  /**
   * Handles 'Remove from System' button click, and triggers modal to
   * remove conversations from the system.
   */
  onRemoveConversationsClick() {
    this.modalService.openDialog(this.viewRef, {
      title: 'Remove from System',
      childComponent: ConversationsRemoveComponent,
      data: {
        conversations: this.conversationsSelected
      }
    });
  }
}
