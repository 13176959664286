import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatasetsModule } from '../datasets/datasets.module'
import { DatasetsService } from '../shared/services/datasets.service'
import { DatasetsComponent } from '../datasets/datasets.component'
import { DatasetOverviewComponent } from '../datasets/dataset-overview/dataset-overview.component';

@NgModule({
  imports: [
    CommonModule,
    DatasetsModule,
  ],
  declarations: [],
  providers: [DatasetsService]
})
export class PlotlyChartModule { }
