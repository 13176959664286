import { Component, ComponentRef, ViewContainerRef, ViewChild } from '@angular/core';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';
import { Dataset } from '../../shared/models/dataset.model';
import { DatasetsService } from '../../shared/services/datasets.service';
import { NgForm } from '@angular/forms';

/**
 * Modal to edit a dataset's name and description.
 */
@Component({
  selector: 'app-dataset-edit',
  templateUrl: './dataset-edit.component.html',
  styleUrls: ['./dataset-edit.component.scss']
})
export class DatasetEditComponent implements IModalDialog {
  /** References the form in the template */
  @ViewChild('form')
  form: NgForm;

  /** Dataset to edit */
  dataset: Dataset;

  constructor(
    private datasetsService: DatasetsService,
    private viewRef: ViewContainerRef
  ) {}

  /**
   * Called after initialization.
   *
   * Receives data from parent component and initializes variables.
   *
   * @param reference
   * @param options Contains modal options (including data) from parent component.
   */
  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<string>>
  ) {
    this.dataset = options.data['dataset'];
  }

  /**
   * Handles form submission.
   */
  onConfirm() {
    const params = {
      dataset_id: this.dataset.getID(),
      edits: {}
    };

    if (this.form.value.datasetName) {
      params['edits']['dataset_name'] = this.form.value.datasetName;
    }

    if (this.form.value.datasetDescription) {
      params['edits'][
        'dataset_description'
      ] = this.form.value.datasetDescription;
    }

    if (this.form.value.datasetHealthCentre) {
      params['edits']['dataset_healthcentre'] = this.form.value.datasetHealthCentre;
    }

    this.datasetsService.editDataset(params);
  }
}
