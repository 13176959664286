/**
 * Model to represent an operation.
 */
export class Operation {
  constructor(
    private created_timestamp: string,
    private dataset_id: number,
    private task_id: number,
    private task_status: string
  ) {}

  /**
   * Returns the value of the given property.
   *
   * @param param Property value to return
   */
  get(param: string): any {
    return this[param];
  }

  /**
   * Returns timestamp of Operation creation.
   */
  getCreatedTimestamp(): string {
    return this.created_timestamp;
  }

  /**
   * Returns ID of the Dataset from which the Operation is created.
   */
  getDatasetID(): number {
    return this.dataset_id;
  }

  /**
   * Returns Operation ID.
   */
  getID(): number {
    return this.task_id;
  }

  /**
   * Returns Operation status.
   */
  getStatus(): string {
    return this.task_status;
  }
}
