import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../shared/auth/login.service';
import { Router } from '@angular/router';
import { FormGroup, NgModel } from '@angular/forms';

/**
 * Component to display the login form.
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(private loginService: LoginService, private router: Router) {}

  /**
   * Handles form submission.
   *
   * @param form References submitted form
   * @param remember References 'Remember' checkbox
   */
  onSubmit(form: FormGroup, remember: NgModel) {
    this.loginService.onLogin(
      form.value.username,
      form.value.password,
      remember.value
    );
  }
}
