import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlotlyChartService {

  constructor(
  ) { }

  public dataToView: string;

  public genderLabels = ['Male', 'Female', 'Other'];

  public ageLabels = ['< 20', '21-35', '36-45', '46-60', '> 60'];

  public topicLabels = [
    'Technical',
    'Hospital', 
    'Outpatient', 
    'Cultural', 
    'Relatives', 
    'Soc. Service', 
    'Work', 
    'Housing', 
    'Substance', 
    'Exercise', 
    'Diet', 
    'Counselling', 
    'Therapy', 
    'Device', 
    'Alternative', 
    'Prescription', 
    'Imaging', 
    'Laboratory', 
    'Diagnosis', 
    'Emotional', 
    'Physical', 
  ]

  public needLabels = ['Health Info', 'Social', 'Emotional', 'Physical'];

  public genderTitle = {
    text: 'Gender',
    font: {
      family: 'Open Sans, sans-serif',
      size: 24,
    },
    xref: 'paper',
    x: 5,
  }

  public ageTitle = {
    text: 'Age',
    font: {
      family: 'Open Sans, sans-serif',
      size: 24,
    },
    xref: 'paper',
    x: 5,
  }

  public topicsTitle = {
    text: 'Conversational Topics',
    font: {
      family: 'Open Sans, sans-serif',
      size: 24,
    },
    xref: 'paper',
    x: 5,
  }

  public needTitle = {
    text: 'Patient Need Types',
    font: {
      family: 'Open Sans, sans-serif',
      size: 24,
    },
    xref: 'paper',
    x: 5,
  }
}
