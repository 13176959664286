import { Component } from '@angular/core';
import { IModalDialog } from 'ngx-modal-dialog';
import { FormGroup } from '@angular/forms';
import { UserService } from '../../shared/services/user.service';
import { PreLoginService } from '../../shared/services/pre-login.service';

/**
 * Modal to display password change form after logging in.
 */
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements IModalDialog {
  /** Array where each element defines a field's icon and inputs */
  // For example:
  // fields = [
  //   password: {
  //     icon: 'fa-lock',
  //     inputs: [ {id: 'password', label: 'Password', type: 'password', validators: [Validators.required] } ],
  //     isPassword: true
  //   }
  // ]
  fields;

  constructor(
    private userService: UserService,
    private preLoginService: PreLoginService
  ) {}

  /**
   * Called after initialization.
   */
  dialogInit() {
    const formFields = this.preLoginService.getFormFields();
    this.fields = [formFields.password, formFields.newPassword];
  }

  /**
   * Handles form submission.
   *
   * @param form References submitted form
   */
  onSubmit(form: FormGroup) {
    this.userService.changePassword(
      form.value.password,
      form.value.newPassword
    );
  }
}
