import { Component, OnInit, Input } from '@angular/core';
import * as d3 from 'd3';

/**
 * Component to display positive/negative donut chart and legend inside the donut.
 */
@Component({
  selector: 'app-piechart-pos-neg',
  templateUrl: './piechart-pos-neg.component.html',
  styleUrls: ['./piechart-pos-neg.component.scss']
})
export class PiechartPosNegComponent {
  /** SVG width */
  width = 300;
  /** SVG height */
  height = 300;

  /** Data [ {name, score, percent}, ... ] to display for donut chart */
  @Input()
  data;
  /** Sum of each data entry's score */
  @Input()
  total;
  /** Piechart inner radius */
  innerRadius = 60;

  /** Map of positive/negative to colors. Hex values are selected from d3.schemePaired. **/
  colors = {
    positive: '#369E2F',
    negative: '#DD1D1A'
  };
  /** Piechart color function */
  color = d3
    .scaleOrdinal()
    .domain(Object.keys(this.colors))
    .range(Object.values(this.colors));

  // Size of each rectangle in legend
  legendRectSize = 15;
  // Vertical spacing between adjacent rectangles in legend
  legendSpacing = 10;

  constructor() {}

  /**
   * Draw legend inside donut chart.
   *
   * Reference: https://bl.ocks.org/cflavs/ff1c6005fd7edad32641
   *
   * @param data Object { radius, g: element that <path> (slice) is appended to, svg }
   */
  drawLegend(data) {
    // Select all '.legend' inside the svg. Currently, they don't exist.
    //
    // data() associates ['positive', 'negative'] with '.legend' elements that will be created.
    // enter() creates placeholder nodes for 'positive' and 'negative'
    // append() replaces placeholders with <g class="legend">
    // transform():
    // - horizontally centers each '.legend'
    // - creates vertical spacing between '.legend' elements, so
    //   whole legend is vertically centered
    const legend = data.svg
      .selectAll('.legend')
      .data(Object.keys(this.colors))
      .enter()
      .append('g')
      .attr('class', 'legend')
      .attr('transform', (d, i) => {
        const horz = -2 * this.legendRectSize;
        const height = this.legendRectSize + this.legendSpacing;
        const offset = (height * this.color.domain().length) / 2;
        const vert = i * height - offset;
        return 'translate(' + [horz, vert] + ')';
      });

    // Append <rect> to each '.legend', and generate color
    legend
      .append('rect')
      .attr('width', this.legendRectSize)
      .attr('height', this.legendRectSize)
      .style('fill', this.color)
      .style('stroke', this.color);

    // Display text for each '.legend'
    // text(d => {...}): d is an entry in ['positive', 'negative']
    legend
      .append('text')
      .attr('x', this.legendRectSize + this.legendSpacing / 2)
      .attr('y', this.legendRectSize)
      .text(d => d);
  }
}
