import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from '../../../../node_modules/rxjs';

/**
 * Service to handle user login and logout.
 */
@Injectable()
export class LoginService {
  /** URL to POST login information */
  loginUrl = '/login';
  /** URL to log out (DELETE) */
  logoutUrl = '/logout';
  /** Route to navigate to after logging in */
  returnUrl: string;

  /** Local storage key to store current user ID */
  currentUserKey = 'currentUser';
  /** Local storage key to store 'true' if login should be persistent. Otherwise, does not exist in storage */
  rememberKey = 'remember';

  constructor(
    private httpService: HttpService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  /**
   * Logs in user or displays notification on error.
   */
  onLogin(username: string, password: string, remember: boolean) {
    const data = {
      username: username,
      password: password
    };

    // If return URL does not exist, set to dashboard route
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    const url = this.httpService.getUrl() + this.loginUrl;
    this.httpService.sendPost(url, data).subscribe(
      response => {
        if (response['logged_in']) {
          this.loginSuccess(response['user_id'], remember);
        } else {
          throw Error('Login failed: ' + response['reason']);
        }
      },

      error => {
        throw error;
      }
    );
  }

  /**
   * Stores current user ID and navigates to this.returnUrl.
   *
   * @param remember True if login should be persistent
   */
  loginSuccess(user_id: number, remember: boolean) {
    localStorage.setItem(this.currentUserKey, user_id.toString());
    if (remember) {
      localStorage.setItem(this.rememberKey, 'true');
    }

    // Get current User from DB (needed to display user information in sidebar)
    this.userService.getUser(user_id).subscribe();
    console.log("return url:", this.returnUrl);
    this.router.navigate([this.returnUrl]);
  }

  /**
   * Removes current user ID from storage and navigates to login page.
   */
  logout() {
    localStorage.removeItem(this.currentUserKey);
    if (localStorage.getItem(this.rememberKey)) {
      localStorage.removeItem(this.rememberKey);
    }

    this.router.navigate(['/STAT']);
  }

  /**
   * Logs out user and navigates to login page.
   */
  onLogout() {
    const url = this.httpService.getUrl() + this.logoutUrl;
    this.httpService.sendDelete(url).subscribe((response: any) => {
      if (!response['logged_in']) {
        this.logout();
      }
    });
  }

  /**
   * Returns an Observable that returns true if cookie exists.
   */
  cookieExists(): Observable<boolean> {
    // If '/conversation/all' returns data successfully, cookie exists.
    const url = this.httpService.getUrl() + '/conversation/all';
    return this.httpService
      .sendGet(url)
      .pipe(
        map(
          response =>
            !response['details'] || !(response['details'] === 'jwt auth failed')
        )
      );
  }

  /**
   * Returns an Observable that returns true if user is logged in.
   */
  getLoginStatus(): Observable<boolean> {
    // If cookie has expired but 'remember' is true, re-login and return true.
    // If cookie exists, return true.
    return this.cookieExists().pipe(
      map(cookieExists => {
        if (!cookieExists && localStorage.getItem(this.rememberKey)) {
          // TODO: re-login and return true. For now return false.

          return false;
        } else {
          console.log("cookie exists:", cookieExists);
          return cookieExists;
        }
      })
    );
  }
}
