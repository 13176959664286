import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { HttpService } from './http.service';

/**
 * Service to interact with the server and perform operations to sign up a user.
 */
@Injectable()
export class SignupService {
  /** URL to POST sign up data */
  signupUrl = '/signup';

  constructor(
    private notificationService: NotificationService,
    private httpService: HttpService
  ) {}

  /**
   * Signs up a user and displays notification on success or error.
   *
   * @param email Email
   * @param firstname First name
   * @param lastname Last name
   * @param username Username
   * @param password Password
   */
  signup(
    email: string,
    firstname: string,
    lastname: string,
    username: string,
    password: string
  ) {
    const data = {
      email: email,
      first_name: firstname,
      last_name: lastname,
      username: username,
      password: password
    };

    const url = this.httpService.getUrl() + this.signupUrl;
    this.httpService.sendPost(url, data).subscribe(
      response => {
        if (response['status'] === 'success') {
          this.notificationService.success(
            'Account created! Please check your email.'
          );
        } else {
          throw Error(response['error']);
        }
      },

      error => {
        throw error;
      }
    );
  }
}
