/**
 * Model to represent a conversation.
 */
export class Conversation {
  constructor(
    private conversation_id: number,
    private conversation_name: string,
    private timestamp_start: string,
    private timestamp_end: string,
    private timestamp_upload: string,
    private num_msgs: number,
    private num_participants: number,
    private tags: string[]
  ) {}

  /**
   * Returns the value of the given property.
   *
   * @param param Property value to return
   */
  get(param: string): any {
    return this[param];
  }

  /**
   * Returns Conversation ID.
   */
  getID(): number {
    return this.conversation_id;
  }

  /**
   * Returns Conversation name.
   */
  getName(): string {
    return this.conversation_name;
  }

  /**
   * Returns timestamp of the earliest message in the Conversation.
   */
  getTimestampStart(): string {
    return this.timestamp_start;
  }

  /**
   * Returns timestamp of the latest message in the Conversation.
   */
  getTimestampEnd(): string {
    return this.timestamp_end;
  }

  /**
   * Returns timestamp of Conversation file upload.
   */
  getTimestampUpload(): string {
    return this.timestamp_upload;
  }

  /**
   * Returns the number of messages in the Conversation.
   */
  getNumMsgs(): number {
    return this.num_msgs;
  }

  /**
   * Returns the number of participants in the Conversation.
   */
  getNumParticipants(): number {
    return this.num_participants;
  }

  /**
   * Returns Conversation tags.
   */
  getTags(): string[] {
    return this.tags;
  }
}
