import { Component, OnInit } from '@angular/core';
import { WebsocketService } from './shared/services/websocket.service';

/**
 * Application root component.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(
    private websocketService: WebsocketService
  ) {}

  ngOnInit() {
    this.websocketService.listen('connect').subscribe((data) => {
      console.log("connected", data);
    })
    this.websocketService.listen('message').subscribe((data) => {
      console.log("messaged", data);
    })
    this.websocketService.listen('progress').subscribe((data) => {
      console.log("progressed", data);
      $("div#percentage")[0].innerText = data['percentage'] + '%';
    })
  }
}
