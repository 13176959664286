import { AppRoutingModule } from '../app-routing.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { LoginComponent } from './login/login.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PreLoginComponent } from './pre-login.component';
import { PreLoginFormComponent } from './pre-login-form/pre-login-form.component';
import { SignupComponent } from './signup/signup.component';

import { PasswordResetService } from '../shared/services/password-reset.service';
import { SignupService } from '../shared/services/signup.service';

/**
 * Module to display the corresponding form and handle form submission for each pre-login route, including
 * login, signup, and password reset.
 */
@NgModule({
  imports: [AppRoutingModule, SharedModule],
  declarations: [
    LoginComponent,
    PasswordChangeComponent,
    PasswordResetComponent,
    PreLoginComponent,
    PreLoginFormComponent,
    SignupComponent
  ],
  providers: [PasswordResetService, SignupService],
  exports: [PreLoginComponent, PreLoginFormComponent]
})
export class PreLoginModule {}
