import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { ConversationsService } from '../../shared/services/conversations.service';
import { DatasetsService } from '../../shared/services/datasets.service';
import { OperationsService } from '../../shared/services/operations.service';
import { Router } from '@angular/router';
import { ModalDialogService } from 'ngx-modal-dialog';
import { DatasetNewComponent } from '../../datasets/dataset-new/dataset-new.component';
import { ConversationNewComponent } from '../../conversations/conversation-new/conversation-new.component';
import { OperationNewComponent } from '../../operations/operation-new/operation-new.component';
import { Dataset } from '../../shared/models/dataset.model';
import { Conversation } from '../../shared/models/conversation.model';
import { Operation } from '../../shared/models/operation.model';

/**
 * Displays the most recent conversations, datasets, or operations.
 * The type of data to display depends on the title provided by the parent component.
 */
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  /** Title is provided by the parent component and must be either 'Conversations', 'Datasets', or 'Operations' */
  @Input()
  title: string;

  /** List of conversations, datasets, or operations */
  data: Conversation[] | Dataset[] | Operation[];
  /** Maximum number of items to display */
  maxNumItems = 5;

  /**
   * Service used is determined by the title, and is either
   * ConversationsService, DatasetsService, or OperationsService.
   */
  service: string;

  constructor(
    private conversationsService: ConversationsService,
    private datasetsService: DatasetsService,
    private operationsService: OperationsService,
    private router: Router,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef
  ) {}

  /**
   * Called after initializing properties.
   */
  ngOnInit() {
    this.service = this.title.toLowerCase() + 'Service'; // ConversationsService, DatasetsService, or OperationsService

    this.getData();
    this[this.service].dataChanged.subscribe(() => this.getData());
  }

  /**
   * Sets this.data to the maxNumItems most recent
   * conversations, datasets, or operations fetched from the database.
   */
  getData() {
    this[this.service].getData(false, this.maxNumItems).subscribe(data => {
      this.data = data;
      this.data =
        this.data.length < this.maxNumItems
          ? this.data
          : this.data.slice(0, this.maxNumItems);
    });
  }

  /**
   * Handles 'View More' button click, and navigates to the corresponding page.
   * For example, if this component displays conversations, the button click navigates to '/conversations'.
   */
  onViewMoreClick() {
    this.router.navigate(['/' + this.title.toLowerCase()]);
  }

  /**
   * Handles 'Add' button click, and triggers modal to:
   *             upload a conversation,
   *             create a new dataset, or
   *             create a new operation
   */
  onAdd() {
    if (this.title === 'Conversations') {
      // Trigger modal to upload conversations
      this.modalService.openDialog(this.viewRef, {
        title: 'Upload',
        childComponent: ConversationNewComponent
      });
    } else if (this.title === 'Datasets') {
      // Trigger modal to create a new dataset and add conversations
      this.modalService.openDialog(this.viewRef, {
        title: 'New Dataset',
        childComponent: DatasetNewComponent
      });
    } else {
      // Trigger modal to run a new operation on a selected dataset
      this.modalService.openDialog(this.viewRef, {
        title: 'New Operation',
        childComponent: OperationNewComponent
      });
    }
  }
}
