import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PasswordResetService } from '../../shared/services/password-reset.service';
import { ActivatedRoute } from '@angular/router';

/**
 * Component to display the password change form.
 */
@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent {
  constructor(
    private passwordResetService: PasswordResetService,
    private route: ActivatedRoute
  ) {}

  /**
   * Handles form submission.
   *
   * @param form References submitted form
   */
  onSubmit(form: FormGroup) {
    this.passwordResetService.resetPassword(
      this.route.snapshot.queryParams['reset_key'],
      form.value.newPassword
    );
  }
}
