import { Component } from '@angular/core';
import { PasswordResetService } from '../../shared/services/password-reset.service';
import { FormGroup } from '@angular/forms';

/**
 * Component to display the email input form (for user to receive a password reset link).
 */
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {
  constructor(private passwordResetService: PasswordResetService) {}

  /**
   * Handles form submission.
   *
   * @param form References submitted form
   */
  onSubmit(form: FormGroup) {
    this.passwordResetService.sendEmail(form.value.email);
  }
}
