import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { Observable } from '../../../../node_modules/rxjs';

/**
 * Service to check user authentication.
 */
@Injectable()
export class AuthService {
  constructor(private loginService: LoginService) {}

  /**
   * Returns an Observable that returns true if the user is authenticated.
   */
  isAuthenticated(): Observable<boolean> {
    return this.loginService.getLoginStatus();
  }
}
