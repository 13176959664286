import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { HttpService } from './http.service';
import { Subject, Observable } from 'rxjs';
import { NotificationService } from './notification.service';
import { map, catchError } from 'rxjs/operators';

/**
 * Service to interact with the server and perform operations to:
 * get a user;
 * change email;
 * change password;
 * clear data;
 * remove account.
 */
@Injectable()
export class UserService {
  /** Current user fetched from the database */
  user: User;
  /** ID of current user */
  userId: number;
  /** Avatar URL of current user */
  userAvatar = 'assets/images/avatar-default.png';

  /** Parent route of all user endpoints */
  baseUrl = this.httpService.getUrl();
  /** URL to GET a user */
  userDataUrl = this.baseUrl + '/user_data';
  /** URL to POST a user topic */
  addUserTopicUrl = this.baseUrl + '/add_user_topic';
  /** URL to DELETE a user topic */
  removeUserTopicUrl = this.baseUrl + '/remove_user_topic';
  /** URL to POST current user's new email */
  changeEmailUrl = this.baseUrl + '/change_email';
  /** URL to POST current user's new password */
  changePasswordUrl = this.baseUrl + '/change_password';
  /** URL to DELETE current user's account */
  deleteAccountUrl = this.baseUrl + '/remove_account';
  /** URL to DELETE current user's data */
  purgeDataUrl = this.baseUrl + '/purge_user_data';

  /** Call after user information in the database has been modified */
  dataChanged = new Subject();

  constructor(
    private httpService: HttpService,
    private notificationService: NotificationService
  ) {}

  /**
   * Returns an Observable that returns a User.
   *
   * @param userId ID of the user to return
   */
  getUser(userId: number): Observable<User> {
    this.userId = userId;

    const url = this.userDataUrl;
    return this.httpService.sendGet(url).pipe(
      map(response => {
        this.user = new User(
          response['first_name'],
          response['last_name'],
          response['username'],
          response['email'],
          response['role'],
          response['assigned_at'],
          response['user_topics'],
          this.userAvatar
        );

        return this.user;
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Adds a default User-defined topic to the account
   *
   * @param userTopic topic to be added
   */
  addUserTopic(userTopic: string) {
    const data = { user_topic: userTopic };

    const url = this.addUserTopicUrl;
    this.httpService.sendPost(url, data).subscribe(
      response => {
        if (response['status'] === 'success') {
          this.notificationService.success('Topic added successfully!');

          // Notify UserComponent that email has changed.
          this.dataChanged.next();
        } else {
          throw Error(response['details']);
        }
      },
      error => {
        throw error;
      }
    );
  }

  /**
   * Removes a default User-defined topic from the account
   *
   * @param userTopic topic to be removed
   */
  removeUserTopic(userTopic: string) {
    const data = { user_topic: userTopic };

    const url = this.removeUserTopicUrl;
    this.httpService.sendPost(url, data).subscribe(
      response => {
        if (response['status'] === 'success') {
          this.notificationService.success('Topic removed successfully!');

          // Notify UserComponent that email has changed.
          this.dataChanged.next();
        } else {
          throw Error(response['details']);
        }
      },
      error => {
        throw error;
      }
    );
  }

  /**
   * Changes current user's email and displays notification upon success or error.
   *
   * @param password Password to confirm
   * @param email User's new email
   */
  changeEmail(password: string, email: string) {
    const data = { password: password, new_email: email };

    const url = this.changeEmailUrl;
    this.httpService.sendPost(url, data).subscribe(
      response => {
        if (response['status'] === 'success') {
          this.notificationService.success('Email changed successfully!');

          // Notify UserComponent that email has changed.
          this.dataChanged.next();
        } else {
          throw Error(response['details']);
        }
      },

      error => {
        throw error;
      }
    );
  }

  /**
   * Changes current user's password and displays notification upon success or error.
   *
   * @param password Password to confirm
   * @param newPassword User's new password
   */
  changePassword(password: string, newPassword: string) {
    const data = { old_password: password, new_password: newPassword };

    const url = this.changePasswordUrl;
    this.httpService.sendPost(url, data).subscribe(
      response => {
        if (response['status'] === 'success') {
          this.notificationService.success('Password changed successfully!');
        } else {
          throw Error(response['details']);
        }
      },

      error => {
        throw error;
      }
    );
  }

  /**
   * Clears current user's data (conversations, datasets, and operations)
   * and displays notification upon success or error.
   *
   * @param password Password to confirm
   */
  purgeData(password: string) {
    const data = { password: password };

    const url = this.purgeDataUrl;
    this.httpService.sendDelete(url, data).subscribe(
      response => {
        if (response['status'] === 'success') {
          this.notificationService.success('Data cleared.');
        } else {
          throw Error(response['details']);
        }
      },

      error => {
        throw error;
      }
    );
  }

  /**
   * Returns an Observable that deletes current user's account and
   * displays notification upon success or error, and
   * redirects to Login page upon success.
   *
   * @param password Password to confirm
   */
  deleteAccount(password: string): Observable<void> {
    const data = { password: password };

    const url = this.deleteAccountUrl;
    return this.httpService.sendDelete(url, data).pipe(
      map(response => {
        if (response['status'] === 'success') {
          this.notificationService.success('Account deleted.');
        } else {
          throw Error(response['details']);
        }
      }),

      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Returns ID of current user.
   */
  getUserId() {
    return this.userId;
  }

  /**
   * Get user topics
   */
  getUserTopics() {
    return this.user.getUserTopics();
  }

  /**
   * Returns avatar URL of current user.
   */
  getDefaultAvatar() {
    return this.userAvatar;
  }
}
