/**
 * Model to represent a user.
 */
export class User {
  constructor(
    private firstname: string,
    private lastname: string,
    private username: string,
    private email: string,
    private role: string,
    private assignedAt: string,
    private userTopics: string[],
    private avatarUrl: string
  ) {}

  /**
   * Returns the value of the given property.
   *
   * @param param Property value to return
   */
  get(param: string): any {
    return this[param];
  }

  /**
   * Returns first name.
   */
  getFirstname(): string {
    return this.firstname;
  }

  /**
   * Returns last name.
   */
  getLastname(): string {
    return this.lastname;
  }

  /**
   * Returns username.
   */
  getUsername(): string {
    return this.username;
  }

  /**
   * Returns email.
   */
  getEmail(): string {
    return this.email;
  }

  /**
   * Returns user role.
   */
  getUserRole(): string {
    return this.role;
  }

  /**
   * Returns assigned locations.
   */
  getAssignedLocs(): string {
    console.log(this.assignedAt)
    if(this.assignedAt == null){
      return "N/A"
    }
    return this.assignedAt;
  }

   /**
   * Returns assigned locations.
   */
  getUserTopics(): string[] {
    return this.userTopics;
  }

  /**
   * Returns avatar URL.
   */
  getAvatarUrl(): string {
    return this.avatarUrl;
  }
}
