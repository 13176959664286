import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';

import { DatatableComponent } from './datatable.component';

/**
 * DataTable for displaying conversations, datasets, or operations.
 *
 * Reference: https://datatables.net/
 */
@NgModule({
  imports: [SharedModule, FormsModule],
  declarations: [DatatableComponent],
  exports: [DatatableComponent]
})
export class DatatableModule {}
