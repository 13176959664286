import { Component, OnInit, Input } from '@angular/core';
import * as d3 from 'd3';

/**
 * Component to display sentiments piechart and slice labels.
 */
@Component({
  selector: 'app-piechart-emotions',
  templateUrl: './piechart-emotions.component.html',
  styleUrls: ['./piechart-emotions.component.scss']
})
export class PiechartEmotionsComponent {
  /** SVG width */
  width = 400;
  /** SVG height */
  height = 360;

  /** Data [ {name, score, percent}, ... ] to display for piechart */
  @Input()
  data;
  /** Sum of each data entry's score */
  @Input()
  total;
  /** Piechart inner radius */
  innerRadius = 10;

  /** Map of sentiments to colors. Hex values are selected from d3.schemePaired. **/
  colors = {
    anger: '#DD1D1A', // red
    anticipation: '#F98204', // dark orange
    disgust: '#AF5928', // brown
    fear: '#6B3AA2', // purple
    joy: '#FCBD70', // light orange
    sadness: '#A5CFDF', // grey-blue
    surprise: '#FDFDA5', // yellow
    trust: '#1D78BC' // blue
  };
  /** Piechart color function */
  color = d3
    .scaleOrdinal()
    .domain(Object.keys(this.colors))
    .range(Object.values(this.colors));

  constructor() {}

  /**
   * Draws pie slice labels.
   *
   * @param data Object { radius, g: element that <path> (slice) is appended to, svg }
   */
  drawLabels(data: any) {
    // Define properties for labels
    const arcLabel = d3
      .arc()
      .outerRadius(data.radius + 5)
      .innerRadius(data.radius + 5);

    // Display label for each non-empty slice.
    //
    // For each <g> (slice):
    // - define text as name of data entry,
    // - center and rotate label using transform():
    //   reference is http://bl.ocks.org/vigorousnorth/7331bb51d4f0c2
    const text = data.g
      .filter(d => d.data.score)
      .append('text')
      .text(d => d.data.name)
      .attr('transform', d => {
        const midAngle =
          d.endAngle < Math.PI
            ? d.startAngle / 2 + d.endAngle / 2
            : d.startAngle / 2 + d.endAngle / 2 + Math.PI;
        return (
          'translate(' +
          [arcLabel.centroid(d)[0], arcLabel.centroid(d)[1]] +
          ') rotate(-90) rotate(' +
          (midAngle * 180) / Math.PI +
          ')'
        );
      })
      .attr('text-anchor', d => (d.endAngle < Math.PI ? 'start' : 'end'))
      .attr('font-size', '0.8em');
  }
}
