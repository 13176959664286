import { Component, ComponentRef } from '@angular/core';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';
import { Conversation } from '../../shared/models/conversation.model';
import { ConversationsService } from '../../shared/services/conversations.service';

/**
 * Modal to remove conversations (provided by the parent component) from the system.
 */
@Component({
  selector: 'app-conversations-remove',
  templateUrl: './conversations-remove.component.html',
  styleUrls: ['./conversations-remove.component.scss']
})
export class ConversationsRemoveComponent implements IModalDialog {
  /** Conversations to remove are provided by the parent component */
  conversations: Conversation[];

  constructor(private conversationsService: ConversationsService) {}

  /**
   * Called after initialization.
   *
   * Receives data from parent component and initializes variables.
   *
   * @param reference
   * @param options Contains modal options (including data) from parent component.
   */
  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<string>>
  ) {
    this.conversations = options.data['conversations'];
  }

  /**
   * Handles confirmation.
   *
   * Removes conversations from the system and updates view.
   */
  onConfirm() {
    const conversationIDs = this.conversations.map(convo => convo.getID());

    let convosRemoved = 0;
    conversationIDs.forEach(conversationID => {
      this.conversationsService
        .removeConversation(conversationID)
        .subscribe(() => {
          // After all selected conversations are removed,
          // notify DatatableComponent to re-fetch conversations and update view
          if (++convosRemoved === conversationIDs.length) {
            this.conversationsService.dataChanged.next();
          }
        });
    });
  }
}
