import { Component, ComponentRef } from '@angular/core';
import { Conversation } from '../../shared/models/conversation.model';
import { ConversationsService } from '../../shared/services/conversations.service';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';
import { Observable } from '../../../../node_modules/rxjs';

/**
 * Modal to display a conversation's information and edit its tags.
 */
@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements IModalDialog {
  /** Conversation to display */
  conversation: Conversation;
  /** Map of Conversation keys to titles */
  conversationTitles: {};
  /** Conversation keys */
  conversationKeys: string[];
  /** Fields starting from this index will be displayed */
  tableStartIndex = 2; // Fields where i < tableStartIndex represent ID and name, which are displayed in the modal title.
  /** Tag-input editor will be displayed if this is true */
  editMode = false;
  /** All tags belonging to the conversation */
  tags: string[];
  /** Tags to add */
  tagsAdded: string[];
  /** Tags to remove */
  tagsRemoved: string[];

  constructor(private conversationsService: ConversationsService) {}

  /**
   * Called after initialization.
   *
   * Receives data from parent component and initializes variables.
   *
   * @param reference
   * @param options Contains modal options (including data) from parent component.
   */
  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<string>>
  ) {
    this.conversation = options.data['conversation'];
    this.init();
  }

  /**
   * Initializes variables.
   */
  init() {
    this.conversationTitles = this.conversationsService.getTitles();
    this.conversationKeys = this.conversationsService.getKeys();

    this.editMode = false;
    this.initTags();
  }

  /**
   * Initializes conversation tags, tags added, and tags removed.
   */
  initTags() {
    this.tags = this.conversation.getTags();
    this.tagsAdded = [];
    this.tagsRemoved = [];
  }

  /**
   * Handles 'Edit Tags' button click.
   */
  onEditTags() {
    this.editMode = true;
  }

  /**
   * Handles tag-input onAdd event.
   * @param tag Tag to add
   */
  onAddTag(tag: string) {
    this.tagsAdded.push(tag);
  }

  /**
   * Handles tag-input onRemove event.
   * @param tag Tag to remove
   */
  onRemoveTag(tag: string) {
    this.tagsRemoved.push(tag);
  }

  /**
   * Handles 'Done' button click, and updates the database with modified tags.
   */
  onUploadTags() {
    this.addTags().subscribe(() => {
      this.removeTags().subscribe(() => this.doneEditTags());
    });
  }

  /**
   * Returns an Observable that updates the database with added tags.
   */
  addTags(): Observable<void> {
    return this.conversationsService.tagConversation(
      this.conversation.getID(),
      this.tagsAdded,
      true
    );
  }

  /**
   * Returns an Observable that updates the database with removed tags.
   */
  removeTags(): Observable<void> {
    return this.conversationsService.tagConversation(
      this.conversation.getID(),
      this.tagsRemoved,
      false
    );
  }

  /**
   * Call after database has been updated with modified tags.
   */
  doneEditTags() {
    this.editMode = false;
    this.tagsAdded = [];
    this.tagsRemoved = [];

    // Notify DatatableComponent to re-fetch conversations and update view
    this.conversationsService.dataChanged.next();
  }
}
