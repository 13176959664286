import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PreLoginModule } from '../pre-login/pre-login.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { ChangeEmailComponent } from './change-email/change-email.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PasswordConfirmComponent } from './password-confirm/password-confirm.component';
import { PurgeDataComponent } from './purge-data/purge-data.component';
import { UserComponent } from './user.component';
import { UserRemoveComponent } from './user-remove/user-remove.component';
import { UserTopicsComponent } from './user-topics/user-topics.component';

/**
 * Module to display the current user's information, and
 * in response to user interactions, display modals to:
 * change email;
 * change password;
 * clear data;
 * remove account.
 */
@NgModule({
  imports: [CommonModule, SharedModule, PreLoginModule, BrowserModule, FormsModule],
  declarations: [
    ChangeEmailComponent,
    ChangePasswordComponent,
    PasswordConfirmComponent,
    PurgeDataComponent,
    UserComponent,
    UserRemoveComponent,
    UserTopicsComponent
  ],
  bootstrap: [ UserComponent ],
  exports: [UserComponent],
  entryComponents: [
    ChangeEmailComponent,
    ChangePasswordComponent,
    PurgeDataComponent,
    UserRemoveComponent,
    UserTopicsComponent
  ]
})
export class UserModule {}
