import { NgModule } from '@angular/core';
import { FileDropModule } from 'ngx-file-drop';
import { SharedModule } from '../shared/shared.module';

import { DatatableModule } from '../datatable/datatable.module';
import { FilterModule } from '../filter/filter.module';

import { ConversationComponent } from './conversation/conversation.component';
import { ConversationNewComponent } from './conversation-new/conversation-new.component';
import { ConversationsComponent } from './conversations.component';
import { ConversationsDatasetEditComponent } from './conversations-dataset-edit/conversations-dataset-edit.component';
import { ConversationsDatasetNewComponent } from './conversations-dataset-new/conversations-dataset-new.component';
import { ConversationsRemoveComponent } from './conversations-remove/conversations-remove.component';

import { FilterService } from '../shared/services/filter.service';

/**
 * Module to display a filtered list of either all conversations or dataset conversations, and
 * in response to user interactions, display modals to:
 * display a conversation's information and edit its tags;
 * upload conversation files;
 * add/remove conversations to/from a dataset;
 * remove conversations from the system.
 */
@NgModule({
  imports: [FileDropModule, SharedModule, DatatableModule, FilterModule],
  declarations: [
    ConversationComponent,
    ConversationNewComponent,
    ConversationsComponent,
    ConversationsDatasetEditComponent,
    ConversationsDatasetNewComponent,
    ConversationsRemoveComponent
  ],
  providers: [FilterService],
  exports: [ConversationsComponent, ConversationNewComponent],
  entryComponents: [
    ConversationComponent,
    ConversationNewComponent,
    ConversationsDatasetEditComponent,
    ConversationsDatasetNewComponent,
    ConversationsRemoveComponent
  ]
})
export class ConversationsModule {}
