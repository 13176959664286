import { Component, ChangeDetectorRef } from '@angular/core';
import { UploadEvent, FileSystemFileEntry } from 'ngx-file-drop';
import { ConversationsService } from '../../shared/services/conversations.service';
import { IModalDialog } from 'ngx-modal-dialog';

/**
 * Modal to upload conversation files.
 */
@Component({
  selector: 'app-conversation-new',
  templateUrl: './conversation-new.component.html',
  styleUrls: ['./conversation-new.component.scss']
})
export class ConversationNewComponent implements IModalDialog {
  /** Files to upload */
  files: File[] = [];

  constructor(
    private conversationsService: ConversationsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /**
   * Called after initialization.
   */
  dialogInit() {
    this.files = [];
  }

  /**
   * Handles file drag-and-drop event.
   *
   * ngx-file-drop documentation: https://www.npmjs.com/package/ngx-file-drop
   */
  onFileDrop(event: UploadEvent) {
    event.files.forEach((file, index) => {
      if (file.fileEntry.isFile) {
        const fileEntry = file.fileEntry as FileSystemFileEntry;

        fileEntry.file((f: File) => {
          this.files.push(f);

          // After adding all files, manually trigger change detection.
          // Otherwise, template does not detect changes to this.files.
          if (index === event.files.length - 1) {
            this.changeDetectorRef.detectChanges();
          }
        });
      }
    });
  }

  /**
   * Handles file input event.
   */
  onFileChange(event: any) {
    this.files.push(...event.target.files);
  }

  /**
   * Handles 'Upload' button click.
   */
  onUpload() {
    let filesUploaded = 0;
    this.files.forEach(file => {
      const formData = new FormData();
      formData.append('convo_file', file, file.name);
      this.conversationsService.uploadConversation(formData).subscribe(() => {
        // After all files are uploaded, notify DatatableComponent to re-fetch conversations and update view
        if (++filesUploaded === this.files.length) {
          this.conversationsService.dataChanged.next();
        }
      });
    });
  }
}
