import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { ConversationsModule } from '../conversations/conversations.module';
import { DatatableModule } from '../datatable/datatable.module';
import { OperationsModule } from '../operations/operations.module';

import { DatasetEditComponent } from './dataset-edit/dataset-edit.component';
import { DatasetsComponent } from './datasets.component';
import { DatasetComponent } from './dataset/dataset.component';
import { DatasetNewComponent } from './dataset-new/dataset-new.component';
import { DatasetRemoveComponent } from './dataset-remove/dataset-remove.component';
import { DatasetOverviewComponent } from './dataset-overview/dataset-overview.component';

import { CommonModule } from '@angular/common';
import * as PlotlyJS from 'plotly.js/dist/plotly.js';
import { PlotlyModule } from 'angular-plotly.js';
import { PlotlyChartComponent } from '../plotly-chart/plotly-chart.component';
import { TagCloudModule } from 'angular-tag-cloud-module';
import { WordcloudComponent } from '../wordcloud/wordcloud.component';
import { StatchartComponent } from '../statchart/statchart.component';
import { LoaderComponent } from '../loader/loader.component';
import { FileDropModule } from 'ngx-file-drop';

import { ChartsModule } from 'ng2-charts';

PlotlyModule.plotlyjs = PlotlyJS;

/**
 * Module to to display the list of all datasets, and in response to user interactions, display modals to:
 * select conversations and add them to a new dataset;
 * remove datasets from the system;
 * display a dataset's information, and filtered lists conversations and operations belonging to the dataset;
 * edit a dataset's name and description;
 * create an operation from a dataset.
 */
@NgModule({
  imports: [
    SharedModule,
    ConversationsModule,
    DatatableModule,
    OperationsModule,

    CommonModule, 
    PlotlyModule,
    TagCloudModule,
    FileDropModule,
    ChartsModule
  ],
  declarations: [
    DatasetComponent,
    DatasetEditComponent,
    DatasetNewComponent,
    DatasetRemoveComponent,
    DatasetsComponent,
    DatasetOverviewComponent,
    PlotlyChartComponent,
    WordcloudComponent,
    StatchartComponent,
    LoaderComponent
  ],
  exports: [DatasetsComponent],
  entryComponents: [
    DatasetEditComponent,
    DatasetNewComponent,
    DatasetRemoveComponent
  ]
})
export class DatasetsModule {}
