import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../shared/services/user.service';
import { User } from '../../../../shared/models/user.model';

/**
 * Component to embed the separate multiConvis application at https://d3v53b4j9jkevb.cloudfront.net into an <iframe>.
 * This is a temporary solution to connect this application to the multiConvis application.
 *
 * Reference: https://codecraft.tv/blog/2017/09/07/angularjs-to-angular-using-iframes/
 */
@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {
  /** Operation whose analysis to display is provided by the parent component */
  @Input()
  operationID;
  /** multiConvis application URL */
  url: SafeResourceUrl;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  /**
   * Called after initializing properties.
   */
  ngOnInit() {
    // multiConvis requires query parameters:
    // 'a=<operationID>&userid=<userID>&interface=<2>&username=<username>'.
    // To pass username as query parameter, need to get current user from DB first.
    const userID = +localStorage.getItem('currentUser');
    this.userService.getUser(userID).subscribe((user: User) => {
      const requestedUrl =
        'https://d3v53b4j9jkevb.cloudfront.net/index.html?a=' +
        this.operationID +
        '&userid=' +
        userID +
        '&interface=2&username=' +
        user.getUsername();

      // To prevent XSS, Angular blocks URL variables and gives
      // the error "unsafe value used in a resource URL context".
      // Use DomSanitizer.bypassSecurityTrustResourceUrl(url) to bypass this.
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(requestedUrl);
    });
  }
}
