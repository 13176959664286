import { Component, ComponentRef, ViewChild } from '@angular/core';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';
import { DatasetsService } from '../../shared/services/datasets.service';
import { NgForm } from '../../../../node_modules/@angular/forms';

/**
 * Modal to create an operation from a dataset.
 */
@Component({
  selector: 'app-operations-dataset-new',
  templateUrl: './operations-dataset-new.component.html',
  styleUrls: ['./operations-dataset-new.component.scss']
})
export class OperationsDatasetNewComponent implements IModalDialog {
  /** References the form in the template */
  @ViewChild('form')
  form: NgForm;

  /** Dataset from which to create an operation */
  datasetID: number;

  constructor(private datasetsService: DatasetsService) {}

  /**
   * Called after initialization.
   *
   * Receives data from parent component and initializes variables.
   *
   * @param reference
   * @param options Contains modal options (including data) from parent component.
   */
  dialogInit(
    reference: ComponentRef<IModalDialog>,
    options: Partial<IModalDialogOptions<string>>
  ) {
    this.datasetID = options.data['datasetID'];
  }

  /**
   * Handles form submission.
   */
  onSubmit() {
    this.datasetsService.createOperation(
      this.datasetID
    );
  }
}
