import { NgModule } from '@angular/core';

import { CardComponent } from './card/card.component';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../shared/shared.module';

/**
 * Module to display the most recent Conversations, Datasets, and Operations.
 */
@NgModule({
  imports: [SharedModule],
  declarations: [CardComponent, DashboardComponent],
  exports: [DashboardComponent]
})
export class DashboardModule {}
