import { Injectable } from '@angular/core';
import { Conversation } from '../models/conversation.model';
import { ConversationsService } from './conversations.service';
import { OperationsService } from './operations.service';
import { Operation } from '../models/operation.model';

/**
 * Service to filter conversations and operations.
 */
@Injectable()
export class FilterService {
  /** Filter to apply */
  filter: any;

  /** Type of data to display must be either 'Conversation' or 'Operation' */
  type: string;
  /** Service used is determined by the type, and is either ConversationsService or OperationsService */
  service: string;

  constructor(
    private conversationsService: ConversationsService,
    private operationsService: OperationsService
  ) {}

  /**
   * Sets this.filter and this.type, and returns a list of filtered items.
   *
   * @param items List of unfiltered items
   * @param filter Filter to apply
   * @param type 'Conversation' or 'Operation'
   */
  transform(
    items: any[],
    filter: any,
    type: string
  ): Conversation[] | Operation[] {
    if (!items || items.length === 0) {
      return items;
    }

    this.filter = filter;
    this.type = type;
    this.service = type + 'Service';

    const resultArray = [];
    for (const item of items) {
      if (this.searchStringMatches(item) && this.filterMatches(item)) {
        resultArray.push(item);
      }
    }

    return resultArray;
  }

  /**
   * Returns true if every search tag is a substring of any tag in tags.
   *
   * @param tags Tags to search through
   * @param searchTags Tags to match
   */
  tagsMatch(tags: string[], searchTags: string[]): boolean {
    const tagsAsString = tags.join('');
    for (const searchTag of searchTags) {
      if (!tagsAsString.includes(searchTag)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Returns true if search string is a substring of any value in the item's fields.
   *
   * @param item Item whose values to search through
   */
  searchStringMatches(item: any): boolean {
    if (!this.filter.searchString) {
      return true;
    }

    this.filter.searchString = this.filter.searchString.toLowerCase();

    // Iterate through each field in the item
    // If field is conversation tags, return true if each search tag
    // is a substring of any conversation tag
    // Otherwise, return true if the search string is a substring of any value in the item
    const keys = Object.keys(this[this.service].getTitles()); // Item fields
    for (const key of keys) {
      if (
        key === 'tags' &&
        this.type === 'conversations' &&
        this.tagsMatch(
          item.getTags(),
          this.filter.searchString.replace(/\s/g, '').split(',')
        )
      ) {
        return true;
      } else if (String(item.get(key)).includes(this.filter.searchString)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Returns true if a number is within a given range.
   *
   * @param min Minimum accepted value
   * @param max Maximum accepted value
   * @param num Number to check
   * @param date Set to true is num is a date
   */
  inRange(min: any, max: any, num: any, date: boolean): boolean {
    if (date) {
      min = min == null || min === '' ? null : new Date(min);
      max = max == null || max === '' ? null : new Date(max);
      num = num == null || num === '' ? null : new Date(num);
    }

    if (min == null && max == null) {
      return true;
    } else if (min != null && max != null) {
      return min <= num && max >= num;
    } else if (min != null) {
      return min <= num;
    } else {
      return max >= num;
    }
  }

  /**
   * Returns true if the item matches either the specified filters.
   *
   * @param item Item to check
   * @param dateCol To specify date filters, set to true. Otherwise, set to false.
   */
  filterColsMatch(item: Conversation | Operation, dateCol: boolean): boolean {
    const cols = dateCol ? this.filter.dateCols : this.filter.filterCols;
    for (const key of Object.keys(cols)) {
      const col = cols[key];
      if (!this.inRange(col.min, col.max, item.get(key), dateCol)) {
        return false;
      }
    }

    return true;
  }

  /**
   * Returns true if item status matches the status filter.
   *
   * @param item Item to check
   */
  statusColMatches(item: any): boolean {
    return (
      this.filter.statusCol === 'all' ||
      this.filter.statusCol === item.getStatus()
    );
  }

  /**
   * Returns true if the item matches the filter.
   *
   * @param item Item to check
   */
  filterMatches(item: Conversation | Operation): boolean {
    if (
      !this.filter ||
      ((!this.filter.dateCols || this.filterColsMatch(item, true)) &&
        (!this.filter.filterCols || this.filterColsMatch(item, false)) &&
        (!this.filter.statusCol || this.statusColMatches(item)))
    ) {
      return true;
    }

    return false;
  }
}
