import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { TagInputModule } from 'ngx-chips';
import { ToastrModule } from 'ngx-toastr';

import { HeaderComponent } from '../header/header.component';

/**
 * Module containing shared modules.
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    TagInputModule,
    ToastrModule.forRoot(),
    ModalDialogModule.forRoot(),
    ReactiveFormsModule
  ],
  declarations: [HeaderComponent],
  exports: [
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    TagInputModule,

    HeaderComponent
  ]
})
export class SharedModule {}
