import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AnalysisComponent } from './operations/analysis/analysis.component';
import { ConversationsComponent } from './conversations/conversations.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DatasetComponent } from './datasets/dataset/dataset.component';
import { DatasetsComponent } from './datasets/datasets.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './pre-login/login/login.component';
import { OperationsComponent } from './operations/operations.component';
import { PasswordChangeComponent } from './pre-login/password-change/password-change.component';
import { PasswordResetComponent } from './pre-login/password-reset/password-reset.component';
import { PreLoginComponent } from './pre-login/pre-login.component';
import { SignupComponent } from './pre-login/signup/signup.component';
import { UserComponent } from './user/user.component';

import { AuthGuard } from './shared/auth/authguard.service';
import { AuthService } from './shared/auth/auth.service';
import { DatasetOverviewComponent } from './datasets/dataset-overview/dataset-overview.component';

/** Maps routes to Components to display */
const routes: Routes = [
  {
    // Routes accessible after login
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'conversations', component: ConversationsComponent },
      { path: 'datasets', component: DatasetsComponent },
      { path: 'datasets/:id', component: DatasetComponent },
      { path: 'datasets-overview/:id', component: DatasetOverviewComponent},
      { path: 'operations', component: OperationsComponent },
      { path: 'operations/:id', component: AnalysisComponent },
      { path: 'user', component: UserComponent },
      { path: '', redirectTo: '/datasets', pathMatch: 'full' }
      // { path: '**', redirectTo: '/dashboard' } // Redirect unknown routes
    ]
  },
  // Routes accessible before login
  {
    path: 'STAT',
    component: PreLoginComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'signup', component: SignupComponent },
      { path: 'password-reset', component: PasswordResetComponent },
      { path: 'password-change', component: PasswordChangeComponent },
      { path: 'login', component: LoginComponent },
      { path: '', redirectTo: '/STAT/login', pathMatch: 'full' }
      // { path: '**', redirectTo: '/STAT/login' } // Redirect unknown routes
    ]
  }
];

/**
 * Module to configure router.
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  providers: [AuthGuard, AuthService],
  exports: [RouterModule]
})
export class AppRoutingModule {}
