import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from '../../../../node_modules/rxjs';
import { environment } from '../../../environments/environment.prod';

/**
 * Service to send HTTP requests to the server and return responses to callers.
 */
@Injectable()
export class HttpService {
  // CORS requires both server and client to give permission to include cookies.
  //
  // For all requests:
  // - Client code must set withCredentials and
  // - Server code must set Access-Control-Allow-Credentials.

  /** HTTP request options */
  requestOptions: {} = { withCredentials: true };

  /** Server root route to use in deployed application */
  deploymentUrl = location.protocol + '//staging.bigapi.statlabs.io';
  /** Server root route to use when testing on development server */
  testingUrl = 'https://staging.app.statlabs.io/api';
  /** Server root route to use when testing on development server */
  localUrl = 'http://localhost:85/api';
  /** Server root route that receives this application's HTTP requests */
  url = environment.apiEndpoint + '/api';

  constructor(private httpClient: HttpClient) {}

  /**
   * Sends HTTP GET request and returns an Observable that returns the response.
   *
   * @param url URL that receives request
   * @param params Optional request parameters
   */
  sendGet(url: string, params?: any): Observable<Object> {
    if (params) {
      return this.httpClient.get(url, {
        ...this.requestOptions,
        params: params
      });
    } else {
      return this.httpClient.get(url, this.requestOptions);
    }
  }

  /**
   * Sends HTTP POST request and returns an Observable that returns the response.
   *
   * @param url URL that receives request
   * @param data Data to POST
   */
  sendPost(url: string, data: any): Observable<Object> {
    return this.httpClient.post(url, data, this.requestOptions);
  }

  /**
   * Sends HTTP DELETE request and returns an Observable that returns the response.
   *
   * @param url URL that receives request
   * @param data Optional request data
   */
  sendDelete(url: string, data?: any): Observable<Object> {
    if (data) {
      return this.httpClient.request('delete', url, {
        ...this.requestOptions,
        body: data
      });
    } else {
      return this.httpClient.request('delete', url, this.requestOptions);
    }
  }

  /**
   * Returns the server root route.
   */
  getUrl(): string {
    return this.url;
  }
}
