import { Component, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { DatasetsService } from '../../shared/services/datasets.service';
import { ActivatedRoute } from '@angular/router';
import { Dataset } from '../../shared/models/dataset.model';
import { ModalDialogService } from 'ngx-modal-dialog';
import { OperationsDatasetNewComponent } from '../operations-dataset-new/operations-dataset-new.component';
import { DatasetEditComponent } from '../dataset-edit/dataset-edit.component';
import { Subscription } from 'rxjs';

/**
 * Component to display a dataset's information, and filtered lists conversations and operations belonging to the dataset
 */
@Component({
  selector: 'app-dataset',
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.scss']
})
export class DatasetComponent implements OnInit, OnDestroy {
  /** Dataset to display */
  dataset: Dataset;
  /** ID of dataset to display */
  datasetID: number;
  /** Page title */
  title: string;
  /** If true, display dataset operations. Otherwise, display dataset conversations. */
  operations = false;
  /** Subscription to datasetsService.datasetChanged to update view after the dataset is changed by another component */
  datasetChangedSubscription: Subscription;

  constructor(
    private datasetsService: DatasetsService,
    private route: ActivatedRoute,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef
  ) {}

  /**
   * Called after initializing properties.
   *
   * Retrieves data from the route and determines the dataset to display.
   */
  ngOnInit() {
    console.log("dataset.component.ts ngOnInit");
    this.datasetID = this.route.snapshot.params['id'];
    console.log("datasetID:",this.datasetID);
    this.getDataset();
    this.datasetChangedSubscription = this.datasetsService.datasetChanged.subscribe(
      () => this.getDataset()
    );
  }

  /**
   * Called when component is destroyed.
   */
  ngOnDestroy() {
    this.datasetChangedSubscription.unsubscribe();
  }

  /**
   * Sets this.dataset to the dataset fetched from the database.
   */
  getDataset() {
    this.datasetsService
      .getDataset(this.datasetID)
      .subscribe((dataset: Dataset) => {
        this.dataset = dataset;
        console.log("getdataset:",this.dataset);
        this.title = this.datasetID + '. ' + this.dataset.getName();
      });
  }

  /**
   * Handles 'Edit Dataset' button click, and triggers modal to edit dataset details.
   */
  onEditDatasetClick() {
    this.modalService.openDialog(this.viewRef, {
      title: 'Edit Dataset',
      childComponent: DatasetEditComponent,
      data: { dataset: this.dataset }
    });
  }

  /**
   * Handles 'Run Operation' button click, and triggers modal to create an operation from this dataset.
   */
  onRunOperationClick() {
    this.datasetsService.createOperation(
      this.datasetID
    );
  }
}
