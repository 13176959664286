import { Component } from '@angular/core';
import { IModalDialog } from 'ngx-modal-dialog';
import { UserService } from '../../shared/services/user.service';
import { FormGroup } from '@angular/forms';
import { LoginService } from '../../shared/auth/login.service';

/**
 * Modal to display password confirmation form and remove the user's account.
 */
@Component({
  selector: 'app-user-remove',
  templateUrl: './user-remove.component.html',
  styleUrls: ['./user-remove.component.scss']
})
export class UserRemoveComponent implements IModalDialog {
  constructor(
    private userService: UserService,
    private loginService: LoginService
  ) {}

  /**
   * Called after initialization.
   */
  dialogInit() {}

  /**
   * Handles form submission.
   *
   * @param form References submitted form
   */
  onSubmit(form: FormGroup) {
    this.userService
      .deleteAccount(form.value.password)
      .subscribe(() => this.loginService.onLogout());
  }
}
