import { Component, Input, Output, EventEmitter } from '@angular/core';
import { INgxMyDpOptions, IMyDateModel } from 'ngx-mydatepicker';

/**
 * Component to display a date filter using start date and the end date date-pickers.
 * Date pickers are displayed using ngx-mydatepicker.
 *
 * Reference: https://github.com/kekeh/ngx-mydatepicker
 */
@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent {
  /** Date title is provided by parent component */
  @Input()
  dateTitle: string;

  /** Notify parent component after changing a date-picker */
  @Output()
  dateChanged = new EventEmitter<{ minMax: string; dateFormatted: string }>();

  /** ngx-mydatepicker options */
  dateOptions: INgxMyDpOptions = {
    dateFormat: 'm/d/yyyy',
    firstDayOfWeek: 'su'
  };

  /** Maps min and max fields to titles */
  dateTitles = {
    min: 'Start Date',
    max: 'End Date'
  };

  constructor() {}

  /**
   * Handles date-picker changes.
   *
   * @param date A date model where date.formatted is the date in the defined format
   * @param minMax 'min' or 'max'
   */
  onDateChanged(date: IMyDateModel, minMax: string) {
    this.dateChanged.emit({ minMax: minMax, dateFormatted: date.formatted });
  }

  /**
   * Returns the list of keys in dateTitles.
   */
  getDateTitlesKeys() {
    return Object.keys(this.dateTitles);
  }
}
